import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';
import TextInput from '@components/common/TextInput';
import { CommonButton } from '@components/common/CommonButton';
import { ReactComponent as NoDiaryIcon } from '@assets/icons/no_diary.svg';
import { convertUnixToSingaporeTime, getSessionUserFromAccessToken } from '@utils/utils';
import React from 'react';
import { List, ListItemText, Typography, Box, CircularProgress, IconButton } from '@mui/material';
import { clsx } from 'clsx';
import { useCreateDiaryEntryMutation, useGetDiaryEntriesQuery } from '@api/reportsSlice';
import { ReactComponent as Copy } from '@assets/icons/copy.svg';
import { generateDiaryComments } from './ReportsDetailedPage/DiaryCommentLogic';

interface DiaryModalProps {
  reportId?: string;
  data?: object;
}

const DiarySection: React.FC<DiaryModalProps> = ({ reportId, data }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPopulating, setIsPopulating] = useState(false);
  const [currentUser, setCurrentUser] = useState({ userId: '' });
  const dispatch = useDispatch();

  const {
    refetch,
    data: diaryData,
    isLoading,
  } = useGetDiaryEntriesQuery(
    {
      reportId: reportId,
    },
    {
      skip: !reportId,
    },
  );

  const [createDiaryEntry] = useCreateDiaryEntryMutation();

  useEffect(() => {
    getSessionUserFromAccessToken().then((user) => {
      setCurrentUser(user);
    });
  }, []);

  const formMethods = useForm({
    mode: 'onSubmit',
  });

  const onSubmit = async (event: any) => {
    setIsSubmitting(true);
    const formValues: any = formMethods.getValues();
    let params = {
      reportId: reportId,
      content: formValues.comment,
    };
    let res: any = await createDiaryEntry(params);

    setIsSubmitting(false);
    if (!res.error) {
      formMethods.reset({ diaryEntry: '' });
      dispatch(
        showAlert({
          type: 'success',
          message: `Diary entry added successfully.`,
        }),
      );
      refetch();
    }
  };

  const onCopy = (index: number) => {
    dispatch(
      showAlert({
        type: 'success',
        message: 'Successfully copied to clipboard',
      }),
    );
    navigator.clipboard.writeText(diaryData?.diaryEntries[index].content);
  };

  const handleButtonClick = async () => {
    try {
      setIsPopulating(true);
      const result = await generateDiaryComments(data);
      const resultArray = result as string[];
      formMethods.setValue('comment', resultArray.join('\n\n'), { shouldValidate: true });
      setIsPopulating(false);
    } catch (error) {
      console.error('Error running logic:', error);
    }
  };

  return (
    <div className="flex-1 min-h-0 flex flex-col justify-between">
      <div className="h-full min-h-0 overflow-auto z-0">
        {diaryData?.diaryEntries && diaryData.diaryEntries?.length > 0 && (
          <List>
            {diaryData?.diaryEntries.map((diaryEntry: any, index: any) => (
              <div key={index} className="pb-2">
                <ListItemText
                  primary={
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Box display="flex" alignItems="center">
                        <div
                          className={clsx(
                            'w-8 h-8 rounded-full text-white flex justify-center items-center font-bold mr-2',
                            currentUser?.userId === diaryEntry.createdBy
                              ? 'bg-blue-400'
                              : 'bg-blue-600',
                          )}
                        >
                          {diaryEntry.initials}
                        </div>
                        <div>{diaryEntry.name}</div>
                      </Box>
                      <IconButton
                        size="small"
                        onClick={() => {
                          onCopy(index);
                        }}
                      >
                        <Copy fontSize="inherit" />
                      </IconButton>
                    </Box>
                  }
                  secondary={
                    <>
                      <div className="mt-2">
                        <Typography variant="body2" color="textSecondary" style={{ whiteSpace: 'pre-line' }}>
                          {diaryEntry.content}
                        </Typography>
                      </div>
                      <div className="mt-2">
                        <Typography variant="caption" color="textSecondary" display="block">
                          {convertUnixToSingaporeTime(diaryEntry.createdAt)}
                        </Typography>
                      </div>
                    </>
                  }
                />
              </div>
            ))}
          </List>
        )}
        {(!diaryData?.diaryEntries || diaryData?.diaryEntries?.length <= 0) &&
          !isLoading &&
          reportId && (
            <div className="flex justify-center items-center align-middle h-full">
              <NoDiaryIcon />
            </div>
          )}
        {(!reportId || isLoading) && (
          <div className="animate-pulse bg-grey-300 h-full w-full"></div>
        )}
      </div>

      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <div className="flex flex-col z-10 gap-2">
          {reportId && !isLoading && (
            <>
              <TextInput
                id={'comment'}
                rules={{ required: 'Please enter a comment' }}
                control={formMethods.control}
                placeholder={'Type your comment here...'}
                formState={formMethods.formState}
                maxRows={1}
                multiline={true}
              />
              <div className="flex flex-row items-end justify-between space-x-4">
                <CommonButton
                  // className="ml-0 w-half flex justify-center"
                  variant="secondary"
                  type="button"
                  title="Populate Diary"
                  onClick={handleButtonClick}
                  isLoading={isPopulating}
                  isDisabled={isPopulating}
                />
                <CommonButton
                  // className="ml-0 w-half flex justify-center"
                  variant="primary"
                  type="submit"
                  title="Post Comment"
                  isLoading={isSubmitting}
                  isDisabled={!formMethods.formState.isValid}
                />
              </div>
            </>
          )}
          {(!reportId || isLoading) && (
            <div className="animate-pulse bg-grey-300 h-full w-full"></div>
          )}
        </div>
      </form>
    </div>
  );
};

export default DiarySection;
