import { useCallback, useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useCreateReportMutation } from '@api/reportsSlice';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import { ReactComponent as ErrorOutline } from '@assets/icons/error_outline.svg';

import DynamicForm from '@components/common/DynamicForm';
import CustomBadge from '@components/common/CustomBadge';
import { createFormGeneralItems } from './CreateReportFormConfig';
import CustomizedStepperForm from '@components/common/Stepper';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { CommonButton } from '@components/common/CommonButton';
import { getSessionUserFromAccessToken } from '@utils/utils';
import AdminCreateReportScamEnablers from './AdminCreateReportScamEnablers';
import AdminCreateReportConfirmation from './AdminCreateReportConfirmation';
import { handleCreateReportButton, validateScamEnablers } from './AdminCreateReportUtility';
import { useLocation, useNavigate } from 'react-router-dom';

const CreateReportComponent = () => {
  const [currentUser, setCurrentUser] = useState<any>({});

  useEffect(() => {
    const getSessionUser = async () => {
      let user;

      user = await getSessionUserFromAccessToken();

      setCurrentUser(user);
    };
    getSessionUser();
    console.log(currentUser);
  }, []);

  const formMethods = useForm({
    values: {
      reportNumber: '',
      scamEnablers: {
        Banks: [{ rows: [{}, {}] }],
        Paynow: [{ rows: [{}, {}] }],
        Monikers: [{}],
        Fintech: [{ rows: [{}, {}] }],
      },
      monetaryLossInvolved: 'false',
      policeStation: '',
      spfEmail: currentUser.email,
    },
    mode: 'all',
  });

  const steps = ['General Information', 'Scam Enablers', 'Confirmation'];
  const satisPoliceStations = ['PID', 'CADID', 'X / DIB'];
  const optionalPoliceStations = ['CD-ASC'];
  const [activeStep, setActiveStep] = useState(0);
  const [stepCompletionStatus, setStepCompletionStatus] = useState(
    new Array(steps.length).fill(false),
  );
  const [badgeText, setBadgeText] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [createReport] = useCreateReportMutation();
  const location = useLocation();
  const policeStation = formMethods.watch('policeStation');

  const handleNext = () => {
    if (activeStep === 1) {
      let errors = validateScamEnablers(formMethods, true);

      if (
        errors?.bank?.length > 0 ||
        errors?.fintech?.length > 0 ||
        errors?.paynow?.length > 0 ||
        errors?.monikers?.length > 0 ||
        errors?.scamEnablers?.length > 0
      ) {
        return;
      }
    }
    setStepCompletionStatus((prev) => {
      const newStatus = [...prev];
      newStatus[activeStep] = true;
      return newStatus;
    });
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  };

  const handleCreateReport = async () => {
    const queryParams = new URLSearchParams(location.search);
    const reportType = queryParams.get('route');

    const breadcrumbsLink =
      reportType === 'reports' ? '/admin/reports' : '/admin/portfolios/monikers';

    await handleCreateReportButton(
      formMethods,
      currentUser,
      dispatch,
      navigate(breadcrumbsLink),
      createReport,
      setLoading,
    );
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    const allPreviousCompleted = stepCompletionStatus.slice(0, step).every((status) => status);
    if (allPreviousCompleted) {
      setActiveStep(step);
    }
  };

  const isButtonDisabled = () => {
    if (activeStep === 0) {
      return !formMethods.formState.isValid && !stepCompletionStatus[activeStep];
    } else if (activeStep === 1) {
      // let formScamEnablers = getScamEnablers(formMethods);
      // if (!formScamEnablers) {
      //   return true;
      // }
      let errors = validateScamEnablers(formMethods, false);
      console.log('errors', errors);
      if (
        errors?.bank?.length > 0 ||
        errors?.fintech?.length > 0 ||
        errors?.paynow?.length > 0 ||
        errors?.monikers?.length > 0 ||
        errors?.scamEnablers?.length > 0
      ) {
        return true;
      }
    }
    return false;
  };

  const getGeneralItems = () => {
    let newFormItems = cloneDeep(createFormGeneralItems);
    let policeStation = newFormItems.find((item) => item.id === 'policeStation');
    if (policeStation) {
      policeStation.optional = true;
      delete policeStation.rules;
    }

    return newFormItems;
  };
  const getHelperText = () => {
    return (
      <div className="flex justify-between items-center">
        <p className="py-4 -mt-6 text-grey-400">
          All fields are required unless marked as optional.
        </p>
        {badgeText.length > 0 && <CustomBadge text={badgeText} />}
      </div>
    );
  };
  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        if (
          optionalPoliceStations.includes(policeStation) ||
          satisPoliceStations.includes(policeStation)
        ) {
          let newFormitem = cloneDeep(getGeneralItems());
          const filteredFormItems = newFormitem.filter(
            (item: FormItemInterface) => item.id !== 'reportNumber',
          );
          return (
            <div className="flex justify-center w-full">
              <div className="flex flex-col gap-2 w-[70%] ">
                {getHelperText()}
                <p className="font-bold text-lg">General Information</p>
                <div className="h-[45vh] 2xl:h-[50vh] overflow-auto">
                  <DynamicForm
                    formFields={filteredFormItems}
                    formMethods={formMethods}
                    setBadgeText={setBadgeText}
                  ></DynamicForm>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="flex justify-center w-full">
              <div className="flex flex-col gap-2 w-[70%] ">
                {getHelperText()}
                <p className="font-bold  text-lg">General Information</p>
                <div className="h-[45vh] 2xl:h-[50vh] overflow-auto">
                  <DynamicForm
                    formFields={getGeneralItems()}
                    formMethods={formMethods}
                    setBadgeText={setBadgeText}
                  ></DynamicForm>
                </div>
              </div>
            </div>
          );
        }
      case 1:
        return (
          <div className="flex flex-col">
            {getHelperText()}
            <AdminCreateReportScamEnablers formMethods={formMethods} />
          </div>
        );
      case 2:
        const formValues: any = formMethods.getValues();
        return (
          <div className="flex justify-center w-full">
            <div className="flex flex-col w-[70%]">
              {getHelperText()}

              <AdminCreateReportConfirmation
                formMethods={formMethods}
                formValues={formValues}
                setActiveStep={setActiveStep}
              />
            </div>
          </div>
        );
      default:
        return 'Unknown stepIndex';
    }
  };

  const getTooltipContent = () => {
    return (
      <div className="flex text-black bg-white">
        <ErrorOutline className="mr-2" />
        <div className="flex flex-col">
          <span>All required fields need to be filled before proceeding to the next section.</span>
          {/* {errors.map((error: string) => (
            <span>{error}</span>
          ))} */}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (
      optionalPoliceStations.includes(policeStation) ||
      satisPoliceStations.includes(policeStation)
    ) {
      formMethods.resetField('reportNumber');
    }
  }, [policeStation, formMethods]);

  // useEffect(() => {
  //   if (formMethods.formState.errors && formMethods.formState?.errors?.scamEnablers) {
  //     setStepCompletionStatus((prev) => {
  //       const newStatus = [...prev];
  //       newStatus[1] = false;
  //       return newStatus;
  //     });
  //   }
  // }, [formMethods.formState.errors?.scamEnablers]);
  return (
    <div className={'px-4 py-8 m-4 flex flex-col gap-4 bg-grey-100 h-full'}>
      <CustomizedStepperForm
        steps={steps}
        handleStepClick={handleStep}
        activeStep={activeStep}
        stepCompletionStatus={stepCompletionStatus}
      >
        <></>
      </CustomizedStepperForm>
      <div className="flex flex-col flex-grow mb-10 h-full max-h-full ">
        <div className="flex-grow">{getStepContent(activeStep)}</div>
        <div className="h-[10%] flex shrink-0 justify-between bg-grey-100 ">
          {activeStep !== 0 && (
            <CommonButton
              onClick={(e: any) => {
                e.preventDefault();
                handleBack();
              }}
              type="button"
              variant="secondary"
              title="Previous"
            />
          )}
          <div className="ml-auto flex">
            {activeStep !== steps.length - 1 ? (
              <Tooltip
                title={getTooltipContent()}
                disableHoverListener={!isButtonDisabled()}
                placement="bottom-end"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: 'common.white',
                      fontSize: '12px',
                    },
                  },
                }}
              >
                <span>
                  <CommonButton
                    variant="primary"
                    title="Next"
                    onClick={handleNext}
                    isDisabled={isButtonDisabled()}
                  />
                </span>
              </Tooltip>
            ) : (
              <CommonButton
                isLoading={loading}
                variant="primary"
                title="Submit"
                onClick={handleCreateReport}
                type="submit"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateReportComponent;
