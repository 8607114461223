import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';
import ModalLayout from '@components/layouts/ModalLayout';
import { getSessionUserFromAccessToken } from '@utils/utils';
import React from 'react';
import { useCreateDiaryEntryMutation, useGetDiaryEntriesQuery } from '@api/reportsSlice';
import DiarySection from '../DiarySection';
import { useGetReportDetailsByIdV2Query } from '@api/reportsSlice';

interface DiaryModalProps {
  isOpen: boolean;
  handleClose: () => void;
  reportId: string;
  reportNum: string;
}

const DiaryModal: React.FC<DiaryModalProps> = ({ isOpen, handleClose, reportId, reportNum }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentUser, setCurrentUser] = useState({ userId: '' });
  const { data: reportData, isLoading, error } = useGetReportDetailsByIdV2Query({ id: reportId }, { skip: !reportId });

  useEffect(() => {
    getSessionUserFromAccessToken().then((user) => {
      setCurrentUser(user);
    });
  }, []);
  
  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalLayout
          title={`Diary (${reportNum})`}
          handleCloseModal={() => {
            handleClose();
          }}
          alignment="right"
          size="full"
        >
          <div className="overflow-hidden h-[84vh] flex flex-col -mt-6">
            <DiarySection reportId={reportId} data={reportData}/>
          </div>
        </ModalLayout>
      </Modal>
    </>
  );
};

export default DiaryModal;
