import { messages, relevantOutcomesForUnfrozenBanks, relevantOutcomesForRelatedPOs } from '.././DiaryCommentConfig';

// Handles Paynow Enabler Logic

function handlePaynowEnabler(platform: string, scamEnabler: any, data: any, messagesArr: string[], outcome: string): boolean {

    if (outcome === 'Archived') {
        return true;
    }
    const paynowNum = scamEnabler.paynowNumber;
    const linkedBankAccount = data.scamEnablers.some(
        (scamEnabler: any) =>
            (scamEnabler.accountNumber &&
                scamEnabler.paynowNumbers.some(
                    (paynowObj: { paynowNumber: string }) => paynowObj.paynowNumber === paynowNum
                  ))
    );

    if (!linkedBankAccount) {
        if (outcome === 'Pending BCS') {
            messagesArr.push(messages.paynowBCSPending.replace('<paynow number>', paynowNum).replace('<payment channel>', platform))
        } else {
            messagesArr.push(
                messages.invalidPaynow.replace('<paynow number>', scamEnabler.paynowNumber)
            );
        }
        return true;
    }
    return false;
}

// Checks whether the scam enabler has any Production Order that is not associated to the current report AND if the scam enabler's outcome
// in the current report is within relevantOutcomesForRelatedPOs. Pushes the relevant diary comment into messagesArr

function checkMatchingProductionOrder(productionOrders: any[], reportId: string, outcome: string, messagesArr: string[], platform: string, accountNumber: string, currentPaynowNumber?: string) {
    if (productionOrders && productionOrders.length > 0) {
        for (const productionOrder of productionOrders) {
            if (productionOrder.poReferenceNo && !productionOrder.poReferenceNo.startsWith(reportId) && 
            relevantOutcomesForRelatedPOs.includes(outcome)) {
                if (currentPaynowNumber) {
                    messagesArr.push(messages.actedOnPreviouslyWithPaynow.replace('<paynow number>', currentPaynowNumber).replace('<payment channel>', platform).replace('<account number>', accountNumber));
                } else {
                    messagesArr.push(messages.actedOnPreviously.replace('<payment channel>', platform).replace('<account number>', accountNumber));
                }
                return true;
            };
        }
        return false;
    }
}

// Handles Bank Enabler Logic

function handleBankEnabler(messagesArr: string[], platform: string, accountNumber: string, hasPaynow: boolean, currentPaynowNumber?: string): void {
    const messageType = hasPaynow ? 'withPaynow' : 'withoutPaynow';
    let message = '';

    switch (platform) {
        case 'SCB':
            message = messages.SCB[messageType];
            break;

        case 'OCBC':
        case 'DBS/POSB':
        case 'UOB':
            message = messages.OCBCDBSUOB[messageType];
            break;

        default:
            message = messages.otherBanks[messageType];
            break;
    }

    if (hasPaynow) {
        message = message.replace('<paynow number>', currentPaynowNumber || '').replace('<payment channel>', platform).replace('<account number>', accountNumber);
    } else {
        message = message.replace('<payment channel>', platform).replace('<account number>', accountNumber);
    }

    messagesArr.push(message);
}

export async function generateDiaryComments(data: any) { 
    if (!data || !data.scamEnablers) {
        return;
    }
    
    const messagesArr: string[] = [];
    const reportId = data.report.reportNumber;

    for (const scamEnabler of data.scamEnablers) {
        const { platform, outcome, paynowNumbers, accountNumber, productionOrders } = scamEnabler;
        const isBank = !!accountNumber;
        const isPaynow = platform === 'Paynow';

        if (isPaynow) {
            if (handlePaynowEnabler(platform, scamEnabler, data, messagesArr, outcome)) {
                continue; 
            }
        }

        if (isBank) {
            if (!paynowNumbers || paynowNumbers.length === 0) {
                if (!checkMatchingProductionOrder(productionOrders, reportId, outcome, messagesArr, platform, accountNumber)) {
                    if (relevantOutcomesForUnfrozenBanks.includes(outcome)) {
                        handleBankEnabler(messagesArr, platform, accountNumber, false);
                    }
                }
                continue;
            }

            for (const paynow of paynowNumbers) {
                let count = 1;
                const currentPaynowNumber = paynow.paynowNumber;

                const isPaynowNumberAScamEnabler = data.scamEnablers.some(
                    (otherScamEnabler: any) =>
                        otherScamEnabler.platform === 'Paynow' &&
                        otherScamEnabler.paynowNumber === currentPaynowNumber
                );

                if (!isPaynowNumberAScamEnabler) {
                    if (count === paynowNumbers.length) {
                        if (!checkMatchingProductionOrder(productionOrders, reportId, outcome, messagesArr, platform, accountNumber)) {
                            if (relevantOutcomesForUnfrozenBanks.includes(outcome)) {
                                handleBankEnabler(messagesArr, platform, accountNumber, false);
                            }
                        }
                    }
                    count += 1;
                    continue;
                }

                if (!checkMatchingProductionOrder(productionOrders, reportId, outcome, messagesArr, platform, accountNumber, currentPaynowNumber)) {
                    if (relevantOutcomesForUnfrozenBanks.includes(outcome)) {
                        handleBankEnabler(messagesArr, platform, accountNumber, true, currentPaynowNumber);
                    }
                }
            };
        }
    };
    return messagesArr;
}