import { FormItemInterface } from '@interface/dynamicFormInterface';
import cloneDeep from 'lodash.clonedeep';
import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';

const DynamicTableView = ({
  tableId,
  formFields,
  data,
  defaultRow,
  showCounter,
}: {
  tableId: string;
  formFields: Array<FormItemInterface>;
  data?: any;
  defaultRow?: any;
  showCounter?: boolean;
}) => {
  const [tableData, setTableData] = useState<any>([]);
  const [processedForm, setProcessedForm] = useState<any>([]);

  const processData = () => {
    const processedForm: any = cloneDeep(formFields);
    setProcessedForm(processedForm);
    if (data[tableId]?.length > 0) {
      let newTableData: any = [];

      data[tableId].forEach((item: any) => {
        if (isEqual(item, defaultRow)) {
        } else {
          newTableData.push(item);
        }
      });
      setTableData(newTableData);
    }
    return processedForm;
  };
  useEffect(() => {
    processData();
  }, [data]);

  return (
    <>
      {processedForm && (
        <div className="mt-5 relative overflow-x-auto rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right">
            <thead>
              <tr className="bg-grey-200 font-bold">
                {processedForm && processedForm.length > 0 && (
                  <>
                    {showCounter && <td className="max-w-5 p-2">No.</td>}
                    {processedForm.map((field: FormItemInterface, index: number) => (
                      <td className="min-w-40 p-2" key={index}>
                        {field.label}
                      </td>
                    ))}
                  </>
                )}
              </tr>
            </thead>

            <tbody>
              {tableData &&
                processedForm &&
                tableData.map((value: any, index: number) => (
                  <tr key={index}>
                    {showCounter && <td className="max-w-5 p-2">{index + 1}</td>}

                    {processedForm.map((field: FormItemInterface, colIndex: number) => (
                      <td className="min-w-40 p-2" key={colIndex}>
                        {field.type === 'screenshot' && JSON.stringify(value[field.id])}
                        {field.type !== 'screenshot' && value[field.id]}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default DynamicTableView;
