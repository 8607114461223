import React, { useEffect, useState } from 'react';
import { TextField, InputAdornment, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { countries } from './countryData'; // Ensure this has both `dialCode` and `name` for each country
import CountrySelector from './CountrySelector';
import { Controller } from 'react-hook-form';
import { getNestedError, getValueByPath } from '@utils/utils';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { ReactComponent as Warning } from '@assets/icons/warning.svg';

const TelephoneInput = ({
  id,
  control,
  rules,
  formState,
  disabled,
  alignment = 'row',
  hideMessage,
  isMultiInput,
}: {
  id: string;
  control: any;
  rules?: any;
  formState?: any;
  disabled?: boolean;
  alignment?: string;
  hideMessage?: boolean;
  isMultiInput?: boolean;
}) => {
  const errors: any = formState?.errors;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    // Assuming the value is provided by the `control` prop via React Hook Form
    let defaultValue: any;

    const arrayFormatMatch = id.match(/(.+)\[(\d+)\]/);
    if (isMultiInput && arrayFormatMatch) {
      const [_, actualId, index] = arrayFormatMatch;
      // Access the value using the extracted string and index
      defaultValue = control._formValues[actualId]?.[index];
    } else {
      // Directly access the value using id
      defaultValue = getValueByPath(control._formValues, id);
    }
    if (defaultValue) {
      const matchedCountry = countries.find((country: any) =>
        defaultValue.startsWith(country.dialCode),
      );
      if (matchedCountry) {
        setSelectedCountry(matchedCountry);
        const numberPart = defaultValue.substring(matchedCountry.dialCode.length);
        setInputValue(numberPart);
      }
    }
  }, [control, id, control._formValues]);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCountrySelect = (country: (typeof countries)[number]) => {
    setSelectedCountry(country);
    setAnchorEl(null);
    return `${country.dialCode}${inputValue}`;
  };

  const handleInputChange = (number: string, country?: any) => {
    const onlyNums = number.replace(/\D/g, '');

    setInputValue(onlyNums);
    if (!onlyNums) {
      return undefined;
    }
    if (country) {
      setSelectedCountry(country);
      return `${country.dialCode}${onlyNums}`;
    }
    return `${selectedCountry.dialCode}${onlyNums}`;
  };

  const handlePaste = (event: any) => {
    event.preventDefault();
    let pastedData = event.clipboardData.getData('Text');
    if (pastedData) {
      pastedData = pastedData.replace(/[^\d+]/g, '');
      const phoneNumber = parsePhoneNumberFromString(
        pastedData.startsWith('+') ? pastedData : `+${pastedData}`,
      );
      console.log(phoneNumber, phoneNumber);
      if (
        pastedData.length === 8 &&
        (pastedData.startsWith('8') || pastedData.startsWith('9') || pastedData.startsWith('6'))
      ) {
        const matchedCountry = countries.find((country: any) => country.code === 'SG');

        return handleInputChange(pastedData, matchedCountry);
      } else if (phoneNumber && phoneNumber.country) {
        const matchedCountry = countries.find((country: any) =>
          phoneNumber!.country!.startsWith(country.code),
        );
        return handleInputChange(phoneNumber.nationalNumber, matchedCountry);
      } else {
        return handleInputChange(pastedData);
      }
    }
    // if (pastedData.startsWith('+')) {
  };

  const validateSGPhoneNumber = (value: any) => {
    if (value && value?.startsWith('+65') && value?.length > 3) {
      // Check if the phone number length is 8 and starts with 8 or 9
      return (
        value?.length === 11 &&
        (value.startsWith('+658') ||
          value.startsWith('+659') ||
          value.startsWith('+656') ||
          value.startsWith('+653'))
      );
    }
    // If the country is not SG, consider it valid by default or add other country validations
    return true;
  };

  const getFinalValue = () => `${selectedCountry.dialCode}${inputValue}`;

  return (
    <>
      <Controller
        control={control}
        name={id}
        rules={{
          ...rules,
          validate: {
            sgPhoneNumber: (value) => validateSGPhoneNumber(value) || 'Invalid SG phone number',
          },
        }}
        render={({ field: { onChange } }) => (
          <div className="flex flex-col">
            {alignment === 'column' && !hideMessage && (
              <p className="text-xs pb-2 text-grey-400">
                You may paste the full number with the country code into the field.
              </p>
            )}
            <TextField
              value={inputValue}
              onChange={(event: any) => {
                onChange(handleInputChange(event.target.value));
              }}
              onPaste={(event: React.ClipboardEvent<HTMLDivElement>) => {
                onChange(handlePaste(event));
              }}
              placeholder="e.g. 91234567 or +6591234567"
              disabled={disabled}
              size="small"
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'white',
                  '&:hover fieldset': {
                    borderColor:
                      errors && getNestedError(errors, id)
                        ? 'var(--red)'
                        : disabled
                          ? 'rgb(189, 189, 189, 0.9)'
                          : 'var(--grey-400)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor:
                      errors && getNestedError(errors, id) ? 'var(--red)' : 'var(--blue-400)',
                  },
                },
                '& fieldset': {
                  borderColor:
                    errors && getNestedError(errors, id) ? 'var(--red)' : 'var(--grey-400)',
                  opacity: (theme: any) => (theme.disabled ? 0.8 : 1),
                },
                '.MuiInputBase-input': {
                  fontSize: '14px',
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <button className="flex items-center gap-1 pr-4" onClick={handleMenuClick}>
                      <img
                        src={selectedCountry.flag}
                        alt={selectedCountry.name}
                        className="w-6 h-6"
                      />
                      <span>{selectedCountry.dialCode}</span>
                      <ArrowDropDownIcon />
                    </button>
                    <span className="text-grey-400 pl-2">|</span> {/* Added divider */}
                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                      <CountrySelector
                        onSelect={(e) => {
                          onChange(handleCountrySelect(e));
                        }}
                      />
                    </Menu>
                  </InputAdornment>
                ),
                endAdornment: errors && getNestedError(errors, id) && (
                  <InputAdornment position="end">
                    <Warning />
                  </InputAdornment>
                ),
              }}
            />

            {alignment === 'row' && !hideMessage && (
              <p className="text-xs pt-2 text-grey-400">
                You may paste the full number with the country code into the field.
              </p>
            )}
          </div>
        )}
      />
      {errors && getNestedError(errors, id) && (
        <span style={{ color: 'var(--red)' }} role="alert">
          {getNestedError(errors, id)?.message}
        </span>
      )}{' '}
    </>
  );
};

export default TelephoneInput;
