import clsx from 'clsx';
import {
  monikerOutcomeStatusMapping,
  proactiveMonikerOutcomeStatusMapping,
  paynowOutcomes,
} from '@interface/constant';

export const ReportStatus = ({ reportStatus }: { reportStatus: string }) => {
  return (
    <div
      className={clsx('rounded-md flex justify-center p-2', {
        'bg-red': reportStatus === 'Pending Review',
        'bg-yellow': reportStatus === 'In Progress',
        'bg-orange': reportStatus === 'Pending Decision' || reportStatus === 'Pending Stakeholders',
        'bg-green': reportStatus === 'Review Completed',
      })}
    >
      <p className="text-white font-bold">{reportStatus}</p>
    </div>
  );
};

export const PaynowStatus = ({ outcome, textOnly }: { outcome: string; textOnly?: boolean }) => {
  return (
    <>
      {textOnly ? (
        <p
          style={{ fontSize: 11 }}
          className={clsx('font-bold', {
            'text-orange': outcome === 'Pending BCS',
            'text-pink': outcome === 'No Records Found',
            'text-green': outcome === 'Records Found',
            'text-grey-400': outcome === 'Archived',
          })}
        >
          {outcome.toUpperCase()}
        </p>
      ) : (
        <div
          className={clsx('rounded-md flex justify-center p-2', {
            'bg-orange': outcome === 'Pending BCS',
            'bg-pink': outcome === 'No Records Found',
            'bg-green': outcome === 'Records Found',
            'bg-grey-400': outcome === 'Archived',
          })}
        >
          <p className="text-white">{outcome}</p>
        </div>
      )}
    </>
  );
};

export const BankStatus = ({ outcome, textOnly }: { outcome: string; textOnly?: boolean }) => {
  return (
    <>
      {textOnly ? (
        <p
          style={{ fontSize: 11 }}
          className={clsx('font-bold', {
            'text-red': outcome === 'Pending Review',
            'text-yellow': outcome === 'Under Review',
            'text-blue-400': outcome === 'PO Issued',
            'text-blue-500': outcome === 'PO Required',
            'text-green':
              outcome === 'Frozen' ||
              outcome === 'Account Closed' ||
              outcome === 'Other Dept Froze',
            'text-pink':
              outcome === 'Not Frozen' ||
              outcome === 'CENTUM - Not Frozen' ||
              outcome === 'Earmarked Funds' ||
              outcome === 'No monies to earmark',
            'text-grey-400': outcome === 'Invalid Account' || outcome === 'Archived',
          })}
        >
          {outcome.toUpperCase()}
        </p>
      ) : (
        <div
          className={clsx('rounded-md flex justify-center p-2', {
            'bg-red': outcome === 'Pending Review',
            'bg-yellow': outcome === 'Under Review',
            'bg-blue-400': outcome === 'PO Issued',
            'bg-blue-500': outcome === 'PO Required',
            'bg-green':
              outcome === 'Frozen' ||
              outcome === 'Account Closed' ||
              outcome === 'Other Dept Froze',
            'bg-pink':
              outcome === 'Not Frozen' ||
              outcome === 'CENTUM - Not Frozen' ||
              outcome === 'Earmarked Funds' ||
              outcome === 'No monies to earmark',
            'bg-grey-400': outcome === 'Invalid Account' || outcome === 'Archived',
          })}
        >
          <p className="text-white">{outcome}</p>
        </div>
      )}
    </>
  );
};

export const MonikerOutcomeStatus = ({
  outcome,
  textOnly,
}: {
  outcome: string;
  textOnly?: boolean;
}) => {
  return (
    <>
      {monikerOutcomeStatusMapping[outcome] && !textOnly && (
        <div
          className={clsx('rounded-md flex justify-center p-2', {
            'bg-orange': outcome === 'Pending Decision',
            'bg-red': outcome === 'Pending Review',
            'bg-green': outcome === 'Terminated',
            'bg-yellow': outcome === 'Under Review',
            'bg-blue-500': outcome === 'PO Required',
            'bg-blue-400': outcome === 'PO Issued',
            'bg-pink': outcome === 'Not Terminated' || outcome === 'Cancelled',
            'bg-grey-400': outcome === 'No Action Required' || outcome === 'Archived',
          })}
        >
          <p className="text-white font-bold">{monikerOutcomeStatusMapping[outcome]}</p>
        </div>
      )}
      {!monikerOutcomeStatusMapping[outcome] && <p className="text-black">-</p>}
      {textOnly && monikerOutcomeStatusMapping[outcome] && (
        <p
          style={{ fontSize: 11 }}
          className={clsx('font-bold', {
            'text-orange': outcome === 'Pending Decision',
            'text-red': outcome === 'Pending Review',
            'text-green': outcome === 'Terminated',
            'text-yellow': outcome === 'Under Review',
            'text-blue-500': outcome === 'PO Required',
            'text-blue-400': outcome === 'PO Issued',
            'text-pink': outcome === 'Not Terminated' || outcome === 'Cancelled',
            'text-grey-400': outcome === 'No Action Required' || outcome === 'Archived',
          })}
        >
          {monikerOutcomeStatusMapping[outcome].toUpperCase()}
        </p>
      )}
    </>
  );
};

export const ProactiveMonikerOutcomeStatus = ({ outcome }: { outcome: string }) => {
  return (
    <>
      {proactiveMonikerOutcomeStatusMapping[outcome] && (
        <div
          className={clsx('rounded-md flex justify-center p-2', {
            'bg-yellow': outcome === 'Under Review',
            'bg-orange': outcome === 'RFI',
            'bg-green': outcome === 'Terminated',
            'bg-blue-400': outcome === 'Submitted',
            'bg-pink': outcome === 'Restrict SG' || outcome === 'Not Terminated',
            'bg-grey-400': outcome === 'No Action Required' || outcome === 'Archived',
          })}
        >
          <p className="text-white font-bold">{proactiveMonikerOutcomeStatusMapping[outcome]}</p>
        </div>
      )}
      {!proactiveMonikerOutcomeStatusMapping[outcome] && <p className="text-black">-</p>}
    </>
  );
};
