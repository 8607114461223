// AlertComponent.js
import { Alert, Link, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import styled from '@emotion/styled';
import { ReactComponent as Info } from '@assets/icons/info_blue.svg';
import { ReactComponent as Warning } from '@assets/icons/warning.svg';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 360,
  },
});

const AlertStaticComponent = ({
  content,
  type,
}: {
  content: string;
  type: 'info' | 'warning' | 'error';
}) => {
  const TooltipContent = () => {
    return (
      <div className="text-black p-4 drop-shadow-xl">
        <h1 className="text-black text-sm font-bold">
          To qualify for the issuance of Direction under OCHA, all the following must be met:
        </h1>
        <ol className="list-decimal pl-3">
          <li>There MUST be a minimum of one (Y) in category A</li>
          <li>There MUST be a minimum of one (Y) in category B</li>
        </ol>
      </div>
    );
  };

  const getAlertContent = () => {
    switch (content) {
      case 'ochaCriteria':
        return (
          <p>
            <span className="text-black">
              For OCHA submission, the following sections must be filled and meet a certain
              criteria.
            </span>
            <CustomWidthTooltip
              title={<TooltipContent />}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: 'common.white',
                    fontSize: '12px',
                  },
                },
              }}
              placement="bottom"
            >
              <span className="font-bold text-blue-500 underline pl-1 cursor-pointer">
                View Criteria
              </span>
            </CustomWidthTooltip>
          </p>
        );
      case 'ochaReassign':
        return (
          <p className="text-black">
            DA2 has reassigned this moniker for reassessment as the{' '}
            <b>identified direction is inappropriate</b> to block the online material/account.
          </p>
        );
      case 'communityGuidelienReassign':
        return (
          <p>
            DA2 has reassigned this moniker for reassessment as the{' '}
            <b>identified channel is inappropriate</b> to block the online material/account.
          </p>
        );
        break;
      default:
        break;
    }
  };

  return (
    <Alert
      severity={type}
      iconMapping={{
        info: <Info />,
        warning: <Warning />,
      }}
      sx={{
        marginBottom: 2,
        backgroundColor:
          type === 'info' ? 'var(--blue-200)' : type === 'warning' ? 'var(--red-100)' : '',
        border: 1,
        borderColor: type === 'info' ? 'var(--blue-400)' : type === 'warning' ? 'var(--red)' : '',
        borderRadius: '4px',
      }}
    >
      {getAlertContent()}
    </Alert>
  );
};

export default AlertStaticComponent;
