import AlertStaticComponent from '@components/common/AlertStaticComponent';
import DynamicForm from '@components/common/DynamicForm';
import RadioInput from '@components/common/Radio';
import { ErrorOutline } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { getConfig } from './AssessmentFindingsConfig';
import { useUpdateAssessmentFindingsV2Mutation } from '@api/scamEnablerSlice';
import { useState } from 'react';
import AssignModal from '@pages/portfolios/monikers/modals/AssignModal';
import { CommonButton } from '@components/common/CommonButton';
import { useDispatch } from 'react-redux';
import { CHECKLIST_VERSION } from '../../../../../constants';

const channelSubmissionItems = {
  label: 'Channel Submission',
  type: 'boolean',
  id: 'channel',
  extras: {
    radioItems: [
      { value: 'OCHA', label: 'OCHA' },
      { value: 'Community Guideline', label: 'Community Guideline' },
    ],
  },
  alignment: 'column',
  rules: {
    required: 'Please select an option',
  },
};

const AssessmentFindingsMonikerPresubmitChecklist1 = ({
  formMethods,
  isReassigned,
  data,
  showOcha,
  setShowOcha,
  currentUser,
  currentRole,
  callback,
  isEditing,
  setIsEditing,
  isDA2Assigned,
  isLoading,
  showEdit,
  productionOrder,
  onReturn,
}: {
  formMethods: any;
  isReassigned: boolean;
  data: any;
  showOcha: boolean;
  setShowOcha: any;
  currentUser: any;
  currentRole: string;
  callback: any;
  isEditing: boolean;
  setIsEditing: any;
  isDA2Assigned: boolean;
  isLoading: boolean;
  showEdit: boolean;
  productionOrder: any;
  onReturn: () => void;
}) => {
  const [version, setVersion] = useState(
    (productionOrder?.scamEnablers &&
      productionOrder?.scamEnablers[0]?.findings?.monikerPreSubmitChecklist1?.version) ||
      data?.scamEnabler?.findings?.monikerPreSubmitChecklist1?.version ||
      CHECKLIST_VERSION,
  );
  const isSubmitDisabled = !formMethods.formState.isValid;

  const handleChannelClick = (value: boolean) => {
    if (value === true) {
      setShowOcha(true);
    } else {
      setShowOcha(false);
    }
  };

  const getAssessmentFindings = () => {
    return (
      <form className="overflow-auto h-[55vh] pb-10">
        {isReassigned && <AlertStaticComponent type="warning" content="ochaReassign" />}

        <DynamicForm
          formFields={getConfig(version)?.assessmentFindingsInitialItems}
          formMethods={formMethods}
          isEditable={isEditing && currentRole === 'da1'}
        ></DynamicForm>
        <div>
          <label className="w-1/2 font-bold mr-4">{channelSubmissionItems.label} </label>

          <RadioInput
            control={formMethods.control}
            defaultValue="OCHA"
            id={channelSubmissionItems.id}
            isEditable={isEditing && currentRole === 'da1'}
            rules={channelSubmissionItems.rules}
            radioItems={channelSubmissionItems.extras.radioItems}
            formState={formMethods.formState}
            handleTrueClick={() => handleChannelClick(true)} // ocha
            handleFalseClick={() => handleChannelClick(false)} // community guideline
          />
        </div>
        {showOcha && (
          <div>
            <DynamicForm
              formFields={getConfig(version)?.assessmentFindingsSubstitutionOrderItems}
              formMethods={formMethods}
              isEditable={isEditing && currentRole === 'da1'}
              isLoading={isLoading}
            ></DynamicForm>
          </div>
        )}
        {showOcha && (
          <div>
            <div className="pt-3 pb-2">
              <label className="w-1/3 font-bold mr-4 pb-1">
                {'Pre-Cleared Criteria to be filled by Assessment Officer'}
              </label>
            </div>
            <AlertStaticComponent type="info" content="ochaCriteria" />
            <DynamicForm
              formFields={getConfig(version)?.assessmentFindingsDa1PartItems}
              formMethods={formMethods}
              isEditable={isEditing && currentRole === 'da1'}
              isLoading={isLoading}
            ></DynamicForm>
          </div>
        )}

        <div className="pt-3">
          {isSubmitDisabled && (
            <div className="flex">
              <ErrorOutline className="mr-2" />
              <span className="italic text-sm">
                All required fields need to be filled before proceeding.
              </span>
            </div>
          )}
        </div>
      </form>
    );
  };

  return (
    <Stack sx={{ width: '100%' }} spacing={1}>
      <div className="flex flex-row justify-between">
        <h1 className="font-semibold text-sm pb-1 text-blue-500">Assessment by DA1</h1>
        <p className="italic text-grey-500">Checklist V{version}</p>
      </div>
      {getAssessmentFindings()}
    </Stack>
  );
};

export default AssessmentFindingsMonikerPresubmitChecklist1;
