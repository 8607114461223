import { Modal } from '@mui/material';
import ModalLayout from '@components/layouts/ModalLayout';
import { getSessionUserFromAccessToken } from '@utils/utils';
import { useEffect, useMemo, useState } from 'react';
import { CommonButton } from '@components/common/CommonButton';

import { useAssignUserToFinancialInstituteMutation } from '@api/financialInstituteSlice';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';
import { DropdownContent } from '@components/common/DropdownContent';

const AssignMeConfirmationModal = ({
  selectedBankAccounts,
  assignOpen,
  onAssignClose,
  callback,
}: {
  selectedBankAccounts: Array<any>;
  assignOpen: boolean;
  onAssignClose: () => void;
  callback: () => void;
}) => {
  const [myUser, setMyUser] = useState({
    userId: '',
    firstName: '',
    lastName: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [assignUser] = useAssignUserToFinancialInstituteMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    getSessionUserFromAccessToken().then((userSession: any) => {
      setMyUser(userSession);
    });
  }, []);

  const newDutyOfficerBankAccList = useMemo(() => {
    return selectedBankAccounts
      .filter((bankAccountInfo: any) => {
        if (bankAccountInfo.dutyOfficer) {
          return bankAccountInfo.dutyOfficer.userId != myUser?.userId;
        }
        return false;
      })
      .map((bankAccountInfo: any) => bankAccountInfo.accountNumber);
  }, [myUser, selectedBankAccounts]);

  const assignToMe = async () => {
    setIsSubmitting(true);

    let r: any = await Promise.all(
      selectedBankAccounts.map((bankAccount: any) => {
        let params = {
          financialInstituteId: bankAccount._id,
          dutyOfficer: myUser.userId,
        };

        return assignUser(params);
      }),
    );

    if (
      r.filter((resp: any) => {
        return !resp.error;
      }).length == r.length
    ) {
      dispatch(
        showAlert({
          type: 'success',
          message: `Selected bank account(s) assigned to ${myUser.firstName} ${myUser.lastName} successfully.`,
        }),
        callback(),
      );
    } else {
      dispatch(
        showAlert({
          type: 'error',
          message: `An unexpected error occurred while assigning bank account(s). Please try again later. `,
        }),
      );
    }

    setIsSubmitting(false);
    onAssignClose();
  };

  return (
    <>
      <Modal open={assignOpen} onClose={onAssignClose}>
        <ModalLayout
          title={'Assign'}
          handleCloseModal={onAssignClose}
          alignment="center"
          size="medium"
        >
          {newDutyOfficerBankAccList.length > 0 && (
            <>
              <p>
                The following bank account(s) have existing duty officer assigned. By proceeding
                with this assignment, these bank account(s) will be transferred to the new duty
                officer.
              </p>
              <ul className="list-disc list-inside">
                {newDutyOfficerBankAccList.map((bankAcc) => (
                  <li className="my-2">{bankAcc}</li>
                ))}
              </ul>
            </>
          )}

          <p className="my-4">Do you want to proceed?</p>

          <DropdownContent
            title={`Selected Bank Accounts: ${selectedBankAccounts.length}`}
            content={selectedBankAccounts.map((bank: any) => (
              <div className="flex gap-6 text-grey-500">
                <p>{bank.accountNumber}</p>
                <p>{bank.platform}</p>
              </div>
            ))}
          />

          <div className="flex gap-4 mr-0 ml-auto mt-10">
            <CommonButton title="Cancel" variant="text" onClick={onAssignClose} />
            <CommonButton
              title="Assign to me"
              variant="primary"
              onClick={assignToMe}
              isLoading={isSubmitting}
            />
          </div>
        </ModalLayout>
      </Modal>
    </>
  );
};

export default AssignMeConfirmationModal;
