import ModalLayout from '@components/layouts/ModalLayout';
import { Modal } from '@mui/material';
import AssessmentFindingsMonikerPresubmitChecklist1 from '../AssessmentFindings/AssessmentFindingsMonikerPresubmitChecklist1';
import cloneDeep from 'lodash.clonedeep';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import AssessmentFindingsMonikerPresubmitChecklist2 from '../AssessmentFindings/AssessmentFindingsMonikerPresubmitChecklist2';

const AssessmentFindingsModal = ({
  showModal,
  handleCloseModal,
  data,
}: {
  showModal: boolean;
  handleCloseModal: any;
  data: any;
}) => {
  const formMethods = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    if (data?.details?.findings?.monikerPreSubmitChecklist1?.checklist) {
      let checklist = data.details.findings.monikerPreSubmitChecklist1.checklist;
      for (let key in checklist) {
        let value = checklist[key];
        formMethods.setValue(key, value, { shouldValidate: true });
      }
    } else if (data?.details?.findings?.monikerPreSubmitChecklist2?.checklist) {
      let checklist = data.details.findings.monikerPreSubmitChecklist2.checklist;
      for (let key in checklist) {
        let value = checklist[key];
        formMethods.setValue(key, value, { shouldValidate: true });
      }
    }
  }, [data]);
  return (
    <Modal open={showModal} onClose={handleCloseModal}>
      <ModalLayout
        title={'Details of Assessment Findings'}
        handleCloseModal={() => {
          handleCloseModal();
        }}
        alignment="right"
        size="full"
        isNestedLayout={true}
        nestedLayourBackTitle={'Back to Activity Log'}
      >
        <div className="flex flex-row gap-6 pt-4 pb-6">
          <p className="text-grey-400">{`Updated By ${data.user}`}</p>
          <p className="text-grey-400">{data.modifiedAt}</p>
        </div>

        <form>
          {data?.details?.findings?.monikerPreSubmitChecklist1 && (
            <AssessmentFindingsMonikerPresubmitChecklist1
              formMethods={formMethods}
              isReassigned={false}
              showOcha={
                data.details.findings.monikerPreSubmitChecklist1.checklist.channel === 'OCHA'
              }
              setShowOcha={() => {}}
              data={cloneDeep(data.details.findings.monikerPreSubmitChecklist1)}
              currentUser={() => {}}
              currentRole={''}
              callback={() => {}}
              isDA2Assigned={false}
              isEditing={false}
              setIsEditing={() => {}}
              isLoading={false}
              showEdit={false}
              productionOrder={{}}
              onReturn={() => {}}
            />
          )}
          {data?.details?.findings?.monikerPreSubmitChecklist2 && (
            <AssessmentFindingsMonikerPresubmitChecklist2
              formMethods={formMethods}
              showOcha={
                !data.details.findings.monikerPreSubmitChecklist2.checklist.checklist2_community_Q1
              }
              data={cloneDeep(data.details.findings.monikerPreSubmitChecklist1)}
              currentRole={''}
              currentUser={() => {}}
              callback={() => {}}
              isDA2Assigned={false}
              isEditing={false}
              setIsEditing={() => {}}
              isLoading={false}
              showEdit={false}
              productionOrder={{}}
              onReturn={() => {}}
            />
          )}
        </form>
      </ModalLayout>
    </Modal>
  );
};
export default AssessmentFindingsModal;
