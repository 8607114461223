import { useWatch } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { Controller } from 'react-hook-form';
import { getNestedError } from '../../utils/utils';
import { useCallback, useEffect, useRef } from 'react';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { IconButton } from './CommonButton';
import ModalLayout from '@components/layouts/ModalLayout';

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const InfoButton = ({ item }: { item: any }) => {
  return (
    (item?.infoText || item?.infoBulletPoints) && (
      <div className="relative group">
        <IconButton isDisabled={false} icon={'info'} size="17px" />

        <div className="hidden group-hover:block drop-shadow	 rounded-md w-32 p-2 z-10 bg-white align-bottom absolute top-0 left-10">
          <p className="text-xs mb-1">{item.infoText}</p>
          <ul className="list-disc list-inside ">
            {item &&
              item.infoBulletPoints &&
              item.infoBulletPoints.map((point: string) => <li className="text-xs">{point}</li>)}
          </ul>
        </div>
      </div>
    )
  );
};

const RadioInput = ({
  control,
  setValue,
  defaultValue,
  id,
  rules,
  formState,
  handleTrueClick,
  handleFalseClick,
  radioItems,
  isEditable = true,
}: {
  control: any;
  setValue?: any;
  defaultValue?: string;
  id: string;
  rules?: any;
  formState?: any;
  handleTrueClick?: any;
  handleFalseClick?: any;
  radioItems?: Array<{
    value: any;
    label: string;
    infoText?: string;
    infoBulletPoints?: Array<string>;
  }>;
  isEditable?: boolean;
}) => {
  const errors = formState?.errors;

  const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
    <FormControlLabel {...props} />
  ))(({ theme, checked }) => ({
    '.MuiFormControlLabel-label': {
      fontSize: '14px',
    },
  }));

  const CustomRadio = styled(Radio)(({ theme }) => ({
    '&.Mui-checked': {
      color: 'var(--blue-500)',
    },
    '&.Mui-focusVisible': {
      boxShadow: 'var(--blue-500)',
    },
    '& .MuiSvgIcon-root': {
      width: '24px',
      height: '24px',
    },
    '& .MuiRadio-root': {
      padding: 0,
    },
  }));

  const DisabledCustomRadio = styled(Radio)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
      width: '24px',
      height: '24px',
    },
    '& .MuiRadio-root': {
      padding: 0,
    },
  }));

  function MyFormControlLabel(props: FormControlLabelProps) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
  }

  const value = useWatch({
    control,
    name: id,
    defaultValue: defaultValue ?? (radioItems && radioItems.length > 0 ? radioItems[0].value : ''),
  });

  //Handle conversion of boolean to string
  useEffect(() => {
    if (value === true || value === false) {
      let newValue = value ? 'true' : 'false';
      if (setValue) setValue(id, newValue, { shouldValidate: true, shouldDirty: true });
    }
  }, [value, setValue, id]);

  return (
    <div>
      <Controller
        name={id}
        control={control}
        rules={rules}
        render={({ field }) => (
          <RadioGroup
            name="use-radio-group"
            value={field.value ?? defaultValue ?? ''}
            onChange={(e) => {
              field.onChange(e);
              if (
                (e.target.value === 'true' ||
                  e.target.value === 'OCHA' ||
                  e.target.value === 'Moniker' ||
                  e.target.value === 'manual') &&
                handleTrueClick
              ) {
                handleTrueClick();
              } else if (
                (e.target.value === 'false' ||
                  e.target.value === 'Community Guideline' ||
                  e.target.value === 'Payment' ||
                  e.target.value === 'uploadList') &&
                handleFalseClick
              ) {
                handleFalseClick();
              }
            }}
          >
            {radioItems ? (
              radioItems.map((item: any, index: number) => (
                <div className="flex align-middle items-center" key={index}>
                  <MyFormControlLabel
                    key={item.value}
                    disabled={!isEditable}
                    value={item.value}
                    label={item.label}
                    control={isEditable ? <CustomRadio /> : <DisabledCustomRadio />}
                  />
                  <InfoButton item={item} />
                </div>
              ))
            ) : (
              <>
                <MyFormControlLabel
                  disabled={!isEditable}
                  value="true"
                  label="Yes"
                  control={isEditable ? <CustomRadio /> : <DisabledCustomRadio />}
                />
                <MyFormControlLabel
                  disabled={!isEditable}
                  value="false"
                  label="No"
                  control={isEditable ? <CustomRadio /> : <DisabledCustomRadio />}
                />
              </>
            )}
          </RadioGroup>
        )}
      />
      {errors && getNestedError(errors, id) && (
        <span style={{ color: 'var(--red)' }} role="alert">
          {getNestedError(errors, id)?.message}
        </span>
      )}
    </div>
  );
};

export default RadioInput;
