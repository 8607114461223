import DynamicForm from '@components/common/DynamicForm';
import RadioInput from '@components/common/Radio';
import { Modal, Stack } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { getConfig } from '../MonikersDetailedPage/AssessmentFindings/AssessmentFindingsConfig';
import { set, useForm } from 'react-hook-form';
import { CommonButton } from '@components/common/CommonButton';
import ModalLayout from '@components/layouts/ModalLayout';
import {
  useCreateProductionOrderV2Mutation,
  useDownloadProductionOrders,
  useSendProductionOrderQueue,
} from '@api/productionOrderSlice';
import Promise from 'bluebird';
import { ReactComponent as ExpandMoreIcon } from '@assets/icons/down_arrow.svg';
import { ReactComponent as ExpandLessIcon } from '@assets/icons/up_arrow.svg';
import { ErrorOutline } from '@mui/icons-material';
import { useScamEnablerAssign, useUpdateAssessmentFindingsV2Mutation } from '@api/scamEnablerSlice';
import { store } from '@store/store';
import { ReactComponent as Warning } from '@assets/icons/warning_yellow.svg';
import { CHECKLIST_VERSION } from '../../../../constants';

const channelSubmissionItems = {
  label: 'Channel Submission',
  type: 'boolean',
  id: 'channel',
  extras: {
    radioItems: [
      { value: 'OCHA', label: 'OCHA' },
      { value: 'Community Guideline', label: 'Community Guideline' },
    ],
  },
  alignment: 'column',
  rules: {
    required: 'Please select an option',
  },
};

const UpdateAssessmentFindingsMonikerPresubmitChecklist1Modal = ({
  isOpen,
  handleClose,
  selectedScamEnablers,
  callback,
}: {
  isOpen: boolean;
  handleClose: () => void;
  selectedScamEnablers: any[];
  callback: () => void;
}) => {
  const formMethods = useForm();
  const [updateAssessmentFindingsV2] = useUpdateAssessmentFindingsV2Mutation();
  const [isAssessmentFindingsAPILoading, setIsAssessmentFindingsAPILoading] = useState(false);
  const sendProductionOrderQueue = useSendProductionOrderQueue();
  const [createProductionOrderV2] = useCreateProductionOrderV2Mutation();
  const downloadProductionOrders = useDownloadProductionOrders();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showGenerateErrorMessage, setShowGenerateErrorMessage] = useState(false);
  const assignUser = useScamEnablerAssign();
  const tableRef = useRef<HTMLDivElement | null>(null);

  const submitPOQueue = async (scamEnablerIds: string[]) => {
    let response = await sendProductionOrderQueue(scamEnablerIds, 'monikerView');
  };

  const [processedSelectedScamEnabler, setProcessedSelectedScamEnabler] = useState<any[]>([]);
  const [validScamEnablers, setValidScamEnablers] = useState<any[]>([]);
  const allSamePlatform = validScamEnablers.every(
    (scamEnabler: any) => scamEnabler.platform === selectedScamEnablers[0].platform,
  );

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isExpanded]);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    let tempProcessedSelectedScamEnabler: any = [];
    let tempValidScamEnablers: any = [];
    selectedScamEnablers.forEach((scamEnabler) => {
      let tempScamEnabler = { ...scamEnabler };
      if (
        scamEnabler.outcome !== 'Pending Review' &&
        scamEnabler.outcome !== 'Not Terminated' &&
        scamEnabler.outcome !== 'No Action Required'
      ) {
        tempScamEnabler.invalid = true;
      } else {
        tempValidScamEnablers.push(scamEnabler);
      }
      tempProcessedSelectedScamEnabler.push(tempScamEnabler);
    });
    setValidScamEnablers(tempValidScamEnablers);
    setProcessedSelectedScamEnabler(tempProcessedSelectedScamEnabler);
  }, [selectedScamEnablers]);

  const generateProductionOrder = async () => {
    let productionOrderRequest: any[] = [];

    await Promise.map(validScamEnablers, async (scamEnabler: any) => {
      productionOrderRequest.push({
        scamEnablerId: scamEnabler._id,
      });
    });
    let params = {
      productionOrderRequest: productionOrderRequest,
      channel: 'Community Guideline',
      platform: validScamEnablers[0].platform,
    };
    let response = await createProductionOrderV2(params);

    if ('error' in response) {
      throw new Error(JSON.stringify(response.error));
    } else if (response.data) {
      if (response.data.productionOrder.length === 1) {
        downloadProductionOrders(response.data.productionOrder[0].productionOrderId, 'v1', false);
      } else {
        let productionOrderIds = response.data.productionOrder.map(
          (row: any) => row.productionOrderId,
        );
        await downloadProductionOrders(productionOrderIds, 'v1', false);
      }

      callback();
    }
  };
  const saveAssessmentFindings = async () => {
    let formResults = formMethods.getValues();
    formResults.channel = 'Community Guideline';
    setIsAssessmentFindingsAPILoading(true);

    getConfig(CHECKLIST_VERSION)?.assessmentFindingsDa1PartItems.forEach((sections: any) => {
      sections.extras.checkboxData.forEach((element: any) => {
        delete formResults[element.id];
      });
    });

    await Promise.map(
      validScamEnablers,
      async (scamEnabler: any) => {
        let params = {
          scamEnablerId: scamEnabler._id,
          monikerPreSubmitChecklist1: {
            version: CHECKLIST_VERSION,
            checklist: formResults,
          },
        };
        let response: any = await updateAssessmentFindingsV2(params);
      },
      { concurrency: 5 },
    );
  };

  const handleSubmit = async (mode: string) => {
    setIsAssessmentFindingsAPILoading(true);
    let scamEnablerIds = validScamEnablers.map((scamEnabler: any) => scamEnabler._id);

    let newAssignParams: any[] = [];
    const state = store.getState();
    const user = state.auth.sessionUser;
    let selectedUser = user.userId;

    validScamEnablers.forEach((scamEnabler) => {
      if (scamEnabler.assignment && scamEnabler.assignment.length > 0) {
        let da1Assignment = scamEnabler.assignment.find(
          (assign: any) => assign.role === 'da1' && assign.userId === selectedUser,
        );
        if (!da1Assignment) {
          newAssignParams.push({
            scamEnablerId: scamEnabler._id,
            userId: selectedUser,
            role: 'da1',
          });
        }
      } else {
        newAssignParams.push({
          scamEnablerId: scamEnabler._id,
          userId: selectedUser,
          role: 'da1',
        });
      }
    });
    if (newAssignParams && newAssignParams.length > 0) {
      let response: any = await assignUser(newAssignParams);
    }
    if (mode === 'save') {
      await saveAssessmentFindings();
    } else if (mode === 'saveAndSendToQueue') {
      await saveAssessmentFindings();
      await submitPOQueue(scamEnablerIds);
    } else if (mode === 'saveAndGeneratePO') {
      await saveAssessmentFindings();
      await generateProductionOrder();
    }
    handleClose();
    setIsAssessmentFindingsAPILoading(false);
  };
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ModalLayout
        title={'Update Assessment Findings'}
        handleCloseModal={handleClose}
        alignment="center"
        size="medium"
        overflowClass="overflow-none"
      >
        <Stack sx={{ width: '100%' }} spacing={4}>
          {processedSelectedScamEnabler.filter((scamEnabler) => {
            return scamEnabler.invalid === true;
          }).length > 0 && (
            <span className="rounded px-2 py-1 font-semibold border border-current bg-[var(--yellow-200)] flex flex-col text-black  items-center gap-1">
              <div className="flex flex-row">
                <Warning />
                <p className="pl-2">
                  Some selected monikers are <b>NOT</b> Pending Review, Not Terminated or No Action
                  Required. These monikers will not be eligible for bulk update of assessment
                  findings.
                  <button
                    className="pl-2 text-blue-500 hover:text-blue-400 underline"
                    onClick={toggleExpansion}
                  >
                    View list of monikers
                  </button>
                </p>
              </div>
            </span>
          )}
          <div className="flex flex-row justify-between">
            <h1 className="font-semibold text-sm pb-1 text-blue-500">Assessment by DA1</h1>
            <p className="italic text-grey-500">Checklist V{CHECKLIST_VERSION}</p>
          </div>

          <div className="overflow-auto max-h-[35vh]">
            <form>
              <DynamicForm
                formFields={getConfig(CHECKLIST_VERSION)?.assessmentFindingsInitialItems}
                formMethods={formMethods}
                isEditable={true}
              ></DynamicForm>
              <div className="pt-5">
                <label className="w-1/2 font-bold mr-4">{channelSubmissionItems.label}: </label>

                <RadioInput
                  control={formMethods.control}
                  defaultValue="Community Guideline"
                  id={channelSubmissionItems.id}
                  isEditable={false}
                  rules={channelSubmissionItems.rules}
                  radioItems={channelSubmissionItems.extras.radioItems}
                  formState={formMethods.formState}
                />
              </div>
            </form>
            {processedSelectedScamEnabler && processedSelectedScamEnabler.length > 0 && (
              <div className="flex flex-col" ref={tableRef}>
                <div className="flex flex-row gap-4 items-center">
                  <h5 className="font-h4-bold-14">{`Selected monikers: ${processedSelectedScamEnabler.length}`}</h5>
                  <button
                    className="py-2 px-4 text-white font-bold rounded-lg my-4 flex items-center justify-center"
                    onClick={toggleExpansion}
                  >
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </button>
                </div>
                {isExpanded && (
                  <div className="overflow-auto bg-grey-200 ">
                    <div className="grid grid-cols-4 py-4 px-2 bg-grey-300">
                      <p className="text-sm font-bold">Scam Enabler User ID</p>
                      <p className="text-sm font-bold">Platform</p>
                      <p className="text-sm font-bold">Outcome</p>
                    </div>
                    {processedSelectedScamEnabler.map((scamEnabler, index) => (
                      <div
                        key={index}
                        className="grid grid-cols-4 p-6 zebra-stripe gap-6 rounded-lg items-center"
                      >
                        <p className="text-sm">{scamEnabler.scamEnablerUserId}</p>
                        <p className="text-sm">{scamEnabler.platform}</p>
                        <p className="text-sm">{scamEnabler.outcome}</p>
                        {scamEnabler.invalid === true && <Warning />}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>

          <div className=" flex flex-col  sticky  bottom-0 pb-4 bg-white">
            <div className={`flex flex-row justify-end pt-4 min-h-8`}>
              <div className={`flex flex-row ${!showGenerateErrorMessage ? 'hidden' : ''}`}>
                <ErrorOutline className="mr-2" />
                <p className="text-grey-400 text-sm italic ">
                  Generation of PO is only allowed if the selected monikers have the same platform
                </p>
              </div>
            </div>
            <div className="flex flex-row gap-5 justify-end pt-2">
              <CommonButton variant="text" title="Cancel" onClick={handleClose} type="button" />
              <CommonButton
                variant="secondary"
                title={'Save Only'}
                type="submit"
                isDisabled={false}
                onClick={(event: any) => {
                  handleSubmit('save');
                }}
                isLoading={isAssessmentFindingsAPILoading}
              />
              <CommonButton
                variant="primary"
                title={'Save & Send to Queue'}
                type="submit"
                isDisabled={false}
                onClick={(event: any) => {
                  handleSubmit('saveAndSendToQueue');
                }}
                isLoading={isAssessmentFindingsAPILoading}
              />
              <CommonButton
                variant="primary"
                title={'Save & Generate PO'}
                type="submit"
                isDisabled={!allSamePlatform}
                onMouseEnter={() => {
                  setShowGenerateErrorMessage(true);
                }}
                onMouseLeave={() => setShowGenerateErrorMessage(false)}
                onClick={(event: any) => {
                  handleSubmit('saveAndGeneratePO');
                }}
                isLoading={isAssessmentFindingsAPILoading}
              />
            </div>
          </div>
        </Stack>
      </ModalLayout>
    </Modal>
  );
};

export default UpdateAssessmentFindingsMonikerPresubmitChecklist1Modal;
