import DynamicForm from '@components/common/DynamicForm';
import ModalLayout from '@components/layouts/ModalLayout';
import { Modal } from '@mui/material';
import { CommonButton } from '@components/common/CommonButton';
import { useEffect, useState } from 'react';
import { FormItemInterface } from '@interface/dynamicFormInterface';

const FilterModal = ({
  filterOpen,
  formItems,
  formMethods,
  onClearFilters,
  onFilterClose,
  onSubmitFilter,
}: {
  filterOpen: boolean;
  formItems: any;
  formMethods: any;
  onClearFilters: any;
  onFilterClose: any;
  onSubmitFilter: any;
}) => {
  const [formFields, setFormFields] = useState<Array<FormItemInterface>>([]);
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    let tempFormFields = formItems;
    setFormFields(tempFormFields);
    setInitialValues(formMethods.getValues());
  }, [filterOpen]);

  const onSubmit = () => {
    let filters = formMethods.getValues();
    onSubmitFilter(filters);
    onFilterClose();
  };

  const handleFilterClose = () => {
    formMethods.reset(initialValues);
    onFilterClose();
  };

  return (
    <Modal open={filterOpen} onClose={handleFilterClose} sx={{ overflow: 'auto' }}>
      <ModalLayout
        title={'Filters'}
        handleCloseModal={handleFilterClose}
        handleCustomLink={onClearFilters}
        customLinkText={'Reset Filters'}
        alignment="right"
        size="full"
        overflowClass="" // allow datepicker modal to work properly
      >
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          {formFields && <DynamicForm formFields={formFields} formMethods={formMethods} isEditable={true}/>}
        </form>
        <div className="flex justify-end gap-4 pt-4">
          <CommonButton
            isDisabled={false}
            variant="text"
            title={`Cancel`}
            onClick={handleFilterClose}
          />
          <CommonButton variant={'primary'} isLoading={false} title="Save" onClick={onSubmit} />
        </div>
      </ModalLayout>
    </Modal>
  );
};

export default FilterModal;
