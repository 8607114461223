import { useEffect, useState } from 'react';
import { AddScamEnablerButton, DeleteScamEnablerButton } from '../Buttons';
import { ScamEnablerSelectorCard } from './components/ScamEnablerSelectorCard';
import { BankSection } from './bankSection/BankSection';
import { PaynowSection } from './paynowSection/PaynowSection';
import { SocialMediaSection } from './socialMediaSection/SocialMediaSection';
import { Link } from 'react-router-dom';
import { payNowPlatforms } from '@interface/constant';
import cloneDeep from 'lodash.clonedeep';
import { GenerateProductionOrderPaynowButton } from '@pages/portfolios/Buttons';
import { ReactComponent as DeleteRed } from '@assets/icons/delete_red.svg';
import AccessControl from '@components/common/AccessControl';

function getType(scamEnabler: any) {
  if (scamEnabler.type && scamEnabler.type === 'Payment') {
    if (payNowPlatforms.includes(scamEnabler.platform)) {
      return 'paynow';
    } else {
      return 'bank';
    }
  } else {
    return 'moniker';
  }
}

export function ScamEnablersSection({
  data,
  refetch,
  reportId,
  currentUser,
}: {
  data: any;
  refetch: () => void;
  reportId: any;
  currentUser: any;
}) {
  const [selectedEnabler, setSelectedEnabler] = useState(0);
  const [listOfScamEnablers, setListOfScamEnablers] = useState<any>([]);

  useEffect(() => {
    if (data?.scamEnablers) {
      let reportNumber = data.report.reportNumber;
      let scamEnablers = cloneDeep(data.scamEnablers);
      scamEnablers.forEach((scamEnabler: any) => {
        let currentReportTransactions: any = [];

        if (scamEnabler.updatedTransactions && scamEnabler.updatedTransactions.length > 0) {
          scamEnabler.updatedTransactions.forEach((transaction: any) => {
            if (transaction?.reportNumber === reportNumber) {
              currentReportTransactions.push(transaction);
            }
          });
          scamEnabler.updatedTransactions = currentReportTransactions;
        }
      });
      scamEnablers = scamEnablers.filter((scamEnabler: any) => scamEnabler.deleted !== true);
      setListOfScamEnablers(scamEnablers);
    }
  }, [data]);
  const delayedCallback = (callback: any) => {
    return () => {
      setTimeout(() => {
        callback();
      }, 2000); // 2-second delay
    };
  };

  const getPermissions = (scamEnablerEntry: any) => {
    if (!scamEnablerEntry) return [];
    let type = getType(scamEnablerEntry);
    switch (type) {
      case 'bank':
        return ['reports.banks.view'];
      case 'paynow':
        return ['reports.paynows.view'];
      case 'moniker':
        return ['reports.scamenablers.view'];
      default:
        return [];
    }
  };
  return (
    <div className="h-full box-border bg-grey-100 rounded-lg flex">
      <div className="flex flex-col w-1/3 h-full border-r-2 border-grey-300 p-4 ">
        {/* Header */}
        <div className="flex flex-col w-full justify-between align-middle gap-2 ">
          <h1 className="font-bold text-lg text-blue-500">Scam Enablers</h1>
          {data && (
            <AddScamEnablerButton
              reportId={reportId}
              callback={delayedCallback(refetch)}
              report={data.report}
              currentUser={currentUser}
            />
          )}
        </div>

        {/* Render moniker selectors */}
        {listOfScamEnablers?.length > 0 && (
          <div className="h-full mt-5 overflow-auto">
            <div>
              <>
                {listOfScamEnablers
                  .sort(
                    (a: any, b: any) =>
                      new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
                  )
                  .map((scamEnablerEntry: any, index: number) => (
                    <>
                      <AccessControl
                        type="component"
                        allowedPermissions={getPermissions(scamEnablerEntry)}
                      >
                        <ScamEnablerSelectorCard
                          key={index}
                          platform={scamEnablerEntry.platform}
                          type={getType(scamEnablerEntry)}
                          status={scamEnablerEntry.outcome}
                          monikerId={
                            scamEnablerEntry.scamEnablerUserId ||
                            scamEnablerEntry.userId ||
                            scamEnablerEntry.accountNumber ||
                            scamEnablerEntry.paynowNumber
                          }
                          isSelected={selectedEnabler == index}
                          setIsSelected={() => {
                            setSelectedEnabler(index);
                          }}
                          isNew={
                            scamEnablerEntry?.platform == 'Paynow' &&
                            !scamEnablerEntry?.lastScreeningAt
                          }
                          isPaynowLinked={scamEnablerEntry?.paynowNumbers?.length > 0}
                        />
                      </AccessControl>
                    </>
                  ))}
              </>
            </div>
          </div>
        )}
        {listOfScamEnablers?.length <= 0 && (
          <div className="animate-pulse bg-grey-300 w-full h-full rounded-lg"></div>
        )}
      </div>

      {/* Show data of selected scam enabler */}
      <AccessControl
        type="component"
        allowedPermissions={getPermissions(listOfScamEnablers[selectedEnabler])}
      >
        <div className="h-full w-2/3 p-4 ">
          {listOfScamEnablers?.length > 0 && (
            <div className="flex flex-col h-full">
              {/* Header section */}
              <div className="flex mb-2 justify-between items-center">
                <div className="flex">
                  <h1 className="font-bold text-lg pb-0 text-blue-500 pr-2">
                    {listOfScamEnablers[selectedEnabler].platform}{' '}
                    {listOfScamEnablers[selectedEnabler].id ||
                      listOfScamEnablers[selectedEnabler].accountNumber}
                  </h1>
                  {getType(listOfScamEnablers[selectedEnabler]) !== 'moniker' && (
                    <DeleteScamEnablerButton
                      selectedScamEnabler={listOfScamEnablers[selectedEnabler]}
                      reportId={reportId}
                      type={getType(listOfScamEnablers[selectedEnabler])}
                      refetch={refetch}
                    />
                  )}
                </div>

                {/* If paynow is new */}

                <div className="ml-2 items-center flex-auto flex justify-between">
                  <div>
                    {listOfScamEnablers[selectedEnabler].platform === 'Paynow' &&
                      !listOfScamEnablers[selectedEnabler].lastScreeningAt && (
                        <p className="bg-blue-400 text-white font-bold rounded-md px-1 h-5">NEW</p>
                      )}
                  </div>
                  <div className="flex  items-center gap-2">
                    {/* Render links */}
                    {getType(listOfScamEnablers[selectedEnabler]) === 'bank' && (
                      <Link
                        to={`/admin/portfolios/bank/${listOfScamEnablers[selectedEnabler]._id}?reportId=${reportId}`}
                        title={`Go to Bank portfolio`}
                        className="text-blue-500 underline text-sm"
                        target="_blank"
                        rel="noopener noreferrer"
                      >{`Go to Bank Portfolio`}</Link>
                    )}
                    {listOfScamEnablers[selectedEnabler].platform === 'Paynow' && (
                      <Link
                        to={{
                          pathname: `/admin/portfolios/paynow/${listOfScamEnablers[selectedEnabler]._id}`,
                        }}
                        title={`Go to PayNow portfolio`}
                        className="text-blue-500 underline text-sm"
                        target="_blank"
                        rel="noopener noreferrer"
                      >{`Go to PayNow Portfolio`}</Link>
                    )}
                    {getType(listOfScamEnablers[selectedEnabler]) === 'moniker' && (
                      <Link
                        to={{
                          pathname: `/admin/portfolios/monikers/${listOfScamEnablers[selectedEnabler]._id}`,
                        }}
                        title={`Go to Moniker Details`}
                        className="text-blue-500 underline text-sm"
                        target="_blank"
                        rel="noopener noreferrer"
                      >{`Go to Moniker Details`}</Link>
                    )}

                    {listOfScamEnablers[selectedEnabler].platform === 'Paynow' && (
                      <GenerateProductionOrderPaynowButton
                        paynowData={listOfScamEnablers[selectedEnabler]}
                        reportNumber={data?.report?.reportNumber}
                        callback={refetch}
                        isDisabled={
                          listOfScamEnablers[selectedEnabler].lastScreeningAt &&
                          listOfScamEnablers[selectedEnabler].outcome == 'Pending BCS'
                        }
                      />
                    )}
                  </div>
                </div>
              </div>

              {listOfScamEnablers[selectedEnabler].paynowNumbers?.length > 0 && (
                <p className="italic text-md text-grey-400 mb-2">
                  This bank account was added from PayNow screening results.
                </p>
              )}

              {/* Actual data */}
              <div className="h-full overflow-auto">
                <div className="">
                  {getType(listOfScamEnablers[selectedEnabler]) === 'bank' && (
                    <BankSection
                      data={listOfScamEnablers[selectedEnabler]}
                      reportNumber={data.report.reportNumber}
                    />
                  )}
                  {getType(listOfScamEnablers[selectedEnabler]) === 'paynow' && (
                    <PaynowSection data={listOfScamEnablers[selectedEnabler]} />
                  )}
                  {getType(listOfScamEnablers[selectedEnabler]) === 'moniker' && (
                    <SocialMediaSection data={listOfScamEnablers[selectedEnabler]} />
                  )}
                </div>
              </div>
            </div>
          )}
          {listOfScamEnablers?.length <= 0 && (
            <div className="animate-pulse bg-grey-300 w-full h-full rounded-lg"></div>
          )}
        </div>
      </AccessControl>
    </div>
  );
}
