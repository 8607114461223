import { ReactComponent as CalendarIcon } from '@assets/icons/calendar.svg';
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import { getNestedError } from '@utils/utils';
import { ConfigProvider, DatePicker } from 'antd';
import styled from '@emotion/styled';

export const createStyledPicker = (PickerComponent: any) => styled(PickerComponent)`
  width: 100%;
  border: solid 1px var(--grey-400);
  font-size: 14px;
  box-sizing: border-box;

  &:hover {
    border-color: var(--grey-400);
    cursor: pointer;
  }

  .ant-picker-input {
    input {
      border: none; // Removes inner border if present
    }
    &:hover input {
      border-color: none;
    }
  }

  &.ant-picker-focused {
    border: solid 2px var(--blue-400);
    box-shadow: none;
  }
`;

const DatePickerComponent = ({
  control,
  formState,
  id,
  placeholder,
  rules,
  showTime = false,
  format,
  removePopupContainer = false,
}: {
  control: any;
  formState?: any;
  id: string;
  placeholder?: string;
  rules?: any;
  showTime?: boolean;
  format?: string;
  removePopupContainer?: boolean;
}) => {
  const errors = formState?.errors;
  const StyledDatePicker = createStyledPicker(DatePicker);

  const isValidDateFormat = (value: string) => {
    let regex;
    if (showTime) {
      regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
    } else {
      regex = /^\d{4}-\d{2}-\d{2}$/;
    }
    return regex.test(value);
  };

  const getCompatibleValue = (value: string) => {
    if (isValidDateFormat(value)) {
      if (showTime) {
        return dayjs(value, 'YYYY-MM-DDTHH:mm');
      } else {
        return dayjs(value, 'YYYY-MM-DD');
      }
    }
    return null;
  };

  const disabledDate = (current: any) => {
    return current && current > dayjs().endOf('day');
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          DatePicker: {
            zIndexPopup: 9999,
          },
        },
        token: {
          colorPrimary: '#6FB8C9',
          colorFillSecondary: 'black',
        },
      }}
    >
      <Controller
        name={id}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div style={{ position: 'relative' }} className="w-full">
            <StyledDatePicker
              disabledDate={disabledDate}
              format={showTime ? format || 'DD/MM/YYYY HH:mm' : format || 'DD/MM/YYYY'}
              getPopupContainer={
                removePopupContainer ? () => document.body : (trigger: any) => trigger.parentElement
              }
              needConfirm={true}
              onChange={(newValue: string | number | Date | dayjs.Dayjs | null | undefined) => {
                let formattedDate;
                if (showTime) {
                  formattedDate = newValue ? dayjs(newValue).format('YYYY-MM-DDTHH:mm') : '';
                } else {
                  formattedDate = newValue ? dayjs(newValue).format('YYYY-MM-DD') : '';
                }
                onChange(formattedDate);
              }}
              placeholder={placeholder}
              showTime={showTime ? { format: 'HH:mm' } : false}
              suffixIcon={<CalendarIcon />}
              value={getCompatibleValue(value)}
            />
          </div>
        )}
      />
      {errors && getNestedError(errors, id) && (
        <span style={{ color: 'var(--red)' }} role="alert">
          {getNestedError(errors, id)?.message}
        </span>
      )}
    </ConfigProvider>
  );
};

export default DatePickerComponent;
