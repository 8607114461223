import { ReactElement, useState } from 'react';
import { Select, MenuItem, TextField, InputAdornment } from '@mui/material';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';
import { ReactComponent as DownArrow } from '@assets/icons/down_arrow.svg';
import { getNestedError } from '@utils/utils';
import { currencies } from '@interface/constant';

const TextInputSelect = ({
  id,
  idCurrency,
  control,
  placeholder,
  formState,
  rules,
}: {
  id: string;
  idCurrency: string;
  control: any;
  placeholder?: string;
  formState?: any;
  rules?: any;
}) => {
  const errors = formState?.errors;

  return (
    <div>
      <Controller
        control={control}
        name={id}
        rules={rules}
        render={({ field: { onChange, onBlur, value, name, ref }, fieldState, formState }) => (
          <TextField
            onBlur={onBlur}
            onChange={(e: any) => {
              onChange(parseInt(e.target.value));
            }}
            value={value ?? ''}
            inputRef={ref}
            type="number"
            size="small"
            sx={{
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor:
                    errors && getNestedError(errors, id) ? 'var(--red)' : 'var(--grey-400)',
                },
                '&.Mui-focused fieldset': {
                  borderColor:
                    errors && getNestedError(errors, id) ? 'var(--red)' : 'var(--blue-400)',
                },
              },
              '& fieldset': {
                borderColor:
                  errors && getNestedError(errors, id) ? 'var(--red)' : 'var(--grey-400)',
              },
              '.MuiInputBase-input': {
                fontSize: '14px',
              },
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                display: 'none',
              },
              '& input[type=number]': {
                MozAppearance: 'textfield',
              },
            }}
            placeholder={placeholder}
            fullWidth
            InputLabelProps={{ shrink: false }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ width: '35%' }}>
                  <Controller
                    name={idCurrency}
                    control={control}
                    render={function ({
                      field: { onChange: onChangeNested, onBlur, value: valueNested, name, ref },
                      fieldState,
                      formState,
                    }: {
                      field: ControllerRenderProps<FieldValues, string>;
                      fieldState: ControllerFieldState;
                      formState: UseFormStateReturn<FieldValues>;
                    }): ReactElement {
                      return (
                        <Select
                          id="currency"
                          value={valueNested || 'SGD'}
                          onChange={onChangeNested}
                          fullWidth
                          IconComponent={() => <DownArrow fontSize={12} />}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                '& .MuiMenuItem-root': {
                                  '& fieldset': {
                                    border: 'solid 1px var(--grey-400)',
                                    borderRadius: '6px',
                                  },
                                },
                                '& .MuiMenuItem-root.Mui-selected': {
                                  backgroundColor: 'var(--blue-300)',
                                },
                                '& .MuiMenuItem-root:hover': {
                                  backgroundColor: 'var(--blue-200)',
                                },
                              },
                            },
                          }}
                          sx={{
                            '& .MuiSelect-select': {
                              padding: '7px 14px',
                              border: 'none',
                              borderLeft: '1px solid var(--grey-400)',
                              borderRadius: 0,
                              whiteSpace: 'normal',
                              overflow: 'visible',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                              borderRadius: 0,
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                              borderRadius: 0,
                            },

                            fontSize: '12px',
                            width: '100%',
                          }}
                        >
                          {currencies.map((curr: string) => (
                            <MenuItem value={curr}>{curr}</MenuItem>
                          ))}
                        </Select>
                      );
                    }}
                  />
                </InputAdornment>
              ),
              sx: { border: 'none' },
            }}
          />
        )}
      />
      {errors && getNestedError(errors, id) && (
        <span style={{ color: 'var(--red)' }} role="alert">
          {getNestedError(errors, id)?.message}
        </span>
      )}
    </div>
  );
};

export default TextInputSelect;
