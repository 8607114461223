export function DataTable({
  data,
  keyMapping,
  extensionButton,
}: {
  data: Array<any>;
  keyMapping?: any;
  extensionButton?: any;
}) {
  return (
    <div className="mt-5 relative overflow-x-auto rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right">
        <thead>
          <tr className="bg-grey-200 font-bold">
            {data.length > 0 &&
              Object.keys(data[0]).map((key: string) => (
                <td className="min-w-40 p-2">{keyMapping[key]}</td>
              ))}
            {extensionButton && <td></td>}
          </tr>
        </thead>

        <tbody>
          {data.map((element: any, index: number) => (
            <tr key={index}>
              {Object.values(element).map((cellValue: any) => (
                <td className={`min-w-40 p-2 bg-${index % 2 ? 'grey-100' : 'white'}`}>
                  {cellValue}
                </td>
              ))}

              {extensionButton && (
                <td className={`p-2 bg-${index % 2 ? 'grey-100' : 'white'}`}>
                  {extensionButton(element, index)}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
