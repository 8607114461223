import Breadcrumbs from '@components/common/Breadcrumbs';
import AdminCreateReportComponent from './AdminCreateReportComponent';
import { useLocation } from 'react-router-dom';

const AdminCreateReportPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reportType = queryParams.get('route');

  const breadcrumbsLabel = reportType === 'reports' ? 'Reports' : 'Monikers(Reported)';
  const breadcrumbsLink =
    reportType === 'reports' ? '/admin/reports' : '/admin/portfolios/monikers';

  return (
    <>
      <div className="w-100 bg-grey-200 p-4 flex flex-row justify-between items-center">
        <Breadcrumbs
          breadcrumbs={[
            { label: breadcrumbsLabel, href: breadcrumbsLink },
            {
              label: `Add Report - Manual Entry`,
              href: `/admin/reports/create`,
              active: true,
            },
          ]}
        />
      </div>

      <div className="flex flex-col h-full items-center justify-center min-h-screen bg-white">
        <div className="flex flex-col w-full h-full gap-2 bg-white  rounded-lg ">
          <AdminCreateReportComponent />
        </div>
      </div>
    </>
  );
};

export default AdminCreateReportPage;
