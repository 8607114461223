import { CommonButton, LoadingButtonComponent } from '@components/common/CommonButton';
import { Modal } from '@mui/material';
import ModalLayout from '@components/layouts/ModalLayout';

import Dropdown from '@components/common/dropdown/Dropdown';
import { useForm } from 'react-hook-form';
import DynamicForm from '@components/common/DynamicForm';
import { useState } from 'react';

const CancellationConfirmationModal = ({
  isOpen,
  onClose,
  handleSubmit,
  channel,
}: {
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (status: string) => void;
  channel: string;
}) => {
  const formMethods = useForm();
  const [isLoadingCancellation, setIsloadingCancellation] = useState(false);

  const dropdownData = [
    {
      id: 'CANCELLED',
      label: 'I want to cancel this OCHA Direction',
      subLabel: 'Cancel only without any substitution',
      type: 'col',
      boldLabel: ['cancel'],
    },
    {
      id: 'VOIDED',
      label: 'I want to void this OCHA Direction',
      subLabel: 'This Production Order was wrongly generated and not sent out',
      type: 'col',
      boldLabel: ['void'],
    },
    {
      id: 'SUBSTITUTED',
      label: 'I want to substitute this OCHA Direction',
      subLabel: 'Need to send another OCHA Direction',
      type: 'col',
      boldLabel: ['substitute'],
    },
  ];

  const onSubmit = async () => {
    setIsloadingCancellation(true);
    if (channel == 'OCHA') {
      let values = formMethods.getValues();
      await handleSubmit(values.status.id);
    } else {
      await handleSubmit('CANCELLED');
    }
    setIsloadingCancellation(false);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalLayout
        title={'Confirmation'}
        handleCloseModal={onClose}
        alignment="center"
        size="small"
      >
        <p className="py-3 -mt-10 text-sm">You are cancelling the production order.</p>
        {channel === 'OCHA' && (
          <>
            <p className="py-2 text-sm">What is the purpose of your cancellation?</p>
            <Dropdown
              id={'status'}
              control={formMethods.control}
              formState={formMethods.formState}
              dropdownData={dropdownData}
              setValue={formMethods.setValue}
              watch={formMethods.watch}
            ></Dropdown>
          </>
        )}
        <p className="pt-4">Once confirmed, you cannot undo this action.</p>
        <div className="flex justify-end gap-4 pt-4">
          <button onClick={onClose}>Cancel</button>
          <CommonButton
            isLoading={isLoadingCancellation}
            title="Confirm"
            variant={isLoadingCancellation ? 'warning-loading' : 'warning'}
            onClick={onSubmit}
          />
        </div>
      </ModalLayout>
    </Modal>
  );
};

export default CancellationConfirmationModal;
