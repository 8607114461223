import Cookies from 'js-cookie';
import { JWK, JWS } from 'node-jose';
import moment from 'moment-timezone';
import { store } from '@store/store';
import { setSessionUser } from '@store/authSlice';
import dayjs from 'dayjs';
import { group } from 'console';
import cloneDeep from 'lodash.clonedeep';
import { FormItemInterface } from '@interface/dynamicFormInterface';

export function getNestedError(errors: any, path: string) {
  return getValueByPath(errors, path);
  // console.log('errors', errors);
  // console.log('path', path);
  // const parts = path.split('.');
  // let current = errors;

  // for (let i = 0; i < parts.length; i++) {
  //   if (current === undefined) return undefined;
  //   const part = parts[i];
  //   if (part.includes('[')) {
  //     const [key, index] = part.split(/\[|\]/).filter(Boolean);
  //     current = current[key]?.[index];
  //   } else {
  //     current = current[part];
  //   }
  // }

  // return current;
}

export const convertYYYYMMDDToUnixTimestamp = (dateString: string): number => {
  return moment(dateString, 'YYYY-MM-DD').startOf('day').valueOf();
};

export const converDateToUnixTimestamp = (dateString: string, format: string): number => {
  return moment.tz(dateString, format, 'Asia/Singapore').valueOf();
};

export function convertUnixToSingaporeTime(
  unixTimestamp: number,
  format: string = 'DD MMM YYYY HH:mm',
) {
  if (!unixTimestamp) return '';
  return moment
    .unix(unixTimestamp / 1000)
    .tz('Asia/Singapore')
    .format(format);
}

export function convertUnixToDynamicFormDateTime(unixTimestamp: number) {
  return dayjs(new Date(unixTimestamp)).format('YYYY-MM-DDTHH:mm');
}

export function convertUnixToDynamicFormDate(unixTimestamp: number) {
  return dayjs(new Date(unixTimestamp)).format('YYYY-MM-DD');
}
export async function getSessionUserFromAccessToken(cookieId = 'SatisPlusAccessToken') {
  try {
    const accessToken = Cookies.get(cookieId);

    if (!accessToken) {
      return null;
    }

    let jwks = {
      keys: [
        {
          use: 'sig',
          alg: 'ES256',
          kty: 'EC',
          kid: 'sYuKKS8p-uh6LS9S8A_g-4MLleP71x52Tp-_XMRxTN0',
          crv: 'P-256',
          x: 'HHwg6eqBMiDE1E5UT1edbd8orcB34zlczHs9Hfeiwak',
          y: 'RtlxQbZwhn2jBiui-8NogpnZyu5KRRuB0U76l79NJKc',
        },
        {
          use: 'sig',
          alg: 'ES256',
          kty: 'EC',
          kid: '2nX5X8MPJ1wwKCGZ4KUj49ENf4MzxJu699bJ9JlbsEg',
          crv: 'P-256',
          x: 'NlcZnCiiJZwPH1MyKy1WXTaR6f6fTwUeY7M3O7Po8WU',
          y: 'UaUAYmSNyH9pO45dl9QPH_buVkt_wGODrgSC4zvHgC4',
        },
      ],
    };

    let payload = await verifyJWS(accessToken, jwks.keys, false);
    store.dispatch(setSessionUser(payload));
    return payload;
  } catch (error) {
    return null;
  }
}

async function verifyJWS(
  jws: string,
  publicKeys: object | string,
  ignoreExpiration: boolean,
  sigKeyAlgWhitelist = ['RS256', 'ES256'],
) {
  try {
    let keyStore;
    let options: any = {};
    if (typeof publicKeys == 'string' && publicKeys.includes('BEGIN')) {
      // convert public key to pem
      keyStore = await JWK.asKey(publicKeys, 'pem');
    } else {
      // JWKS
      keyStore = await JWK.asKeyStore(publicKeys);
      options.algorithms = sigKeyAlgWhitelist;
    }

    let result: any = await JWS.createVerify(keyStore, options).verify(jws);
    let payload = JSON.parse(Buffer.from(result.payload).toString());

    if (!ignoreExpiration) {
      let exp = payload.exp;
      let currentTimeStamp = Math.floor(Date.now() / 1000);

      if (currentTimeStamp > exp) {
        throw { message: 'JWS expired' };
      }
    }

    return payload;
  } catch (error) {
    console.log('Error with verifying JWS:', error);
    throw 'Error with verifying JWS: ' + error;
  }
}

export function convertMsToHoursMinutes(ms: number) {
  let hours = Math.floor(ms / 1000 / 60 / 60);
  let minutes = Math.floor((ms / 1000 / 60) % 60);
  return { hours, minutes };
}

export function convertMsToDDMMYYYYHHmm(unixTimestamp: number) {
  if (!unixTimestamp) return '';

  return moment(unixTimestamp).format('DD/MM/YYYY hh:mmA');
}

export function convertMsToYYYYMMDD(unixTimestamp: number) {
  if (!unixTimestamp) return '';

  return moment(unixTimestamp).format('YYYY-MM-DD');
}

export const getFileNameFromContentDisposition = (contentDisposition: string) => {
  if (!contentDisposition) return 'file';
  const fileName = contentDisposition.split('filename=')[1];
  return fileName;
};

export const generateReportNumber = (prefix: string, counter?: number) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JavaScript
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${prefix}/${year}${month}${day}/${hours}${minutes}${seconds}${counter ? counter.toString() : ''}`;
};

export const convertValues = (values: any) => {
  return Object.fromEntries(
    Object.entries(values).map(([key, value]) => {
      if (value === 'true') return [key, true];
      if (value === 'false') return [key, false];
      return [key, value];
    }),
  );
};

export const getFileMimeTypes = (types: Array<string>) => {
  let fileMimeTypes = [];
  for (let type of types) {
    switch (type) {
      case 'image':
        fileMimeTypes.push('image/png');
        fileMimeTypes.push('image/jpeg');
        break;
      case 'word':
        fileMimeTypes.push('application/msword');
        fileMimeTypes.push(
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        );
        break;
      case 'pdf':
        fileMimeTypes.push('application/pdf');
        break;
      default:
        break;
    }
  }
  return fileMimeTypes;
};

export async function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function cleanUpWhatsappNumber(whatsappNumber: string) {
  // clean up whatsapp numbers
  //remove all special characters except numbers from element.userId except + in the first character
  //if userId does not have + in front and is length 8 and starts with 8 or 9 , it is a singapore number, add +65 in front
  //if userId does not have + in front and is not a singapore number, add + in front
  whatsappNumber = whatsappNumber.replace(/[^\d+]/g, '');
  if (!whatsappNumber || !whatsappNumber.trim()) return '';
  if (
    whatsappNumber.length === 8 &&
    (whatsappNumber.startsWith('8') ||
      whatsappNumber.startsWith('9') ||
      whatsappNumber.startsWith('6'))
  ) {
    whatsappNumber = '+65' + whatsappNumber;
  } else if (
    whatsappNumber.length === 10 &&
    (whatsappNumber.startsWith('658') ||
      whatsappNumber.startsWith('659') ||
      whatsappNumber.startsWith('656'))
  ) {
    whatsappNumber = '+' + whatsappNumber;
  } else if (!whatsappNumber.startsWith('+')) {
    whatsappNumber = '+' + whatsappNumber;
  }
  return whatsappNumber;
}

// Extracted function to validate URL and extract user ID
export const extractUserIdFromUrl = (url: string, platform: string) => {
  let isValidUrl = false;
  let userId = '';
  if (platform === 'Facebook') {
    if (url.startsWith('https://www.facebook.com/profile.php?id=')) {
      userId = url.replace('https://www.facebook.com/profile.php?id=', '');
      isValidUrl = true;
    } else if (url.startsWith('https://www.facebook.com/')) {
      userId = url.replace('https://www.facebook.com/', '');
      isValidUrl = true;
    }
  } else if (platform === 'Instagram') {
    if (url.startsWith('https://www.instagram.com/')) {
      userId = url.replace('https://www.instagram.com/', '');
      isValidUrl = true;
    }
  }

  return { isValidUrl, userId };
};

export const getAmountLoss = (data: any) => {
  let totalLoss = 0;
  if (data?.amountLoss || data?.report?.amountLoss) {
    totalLoss = data.amountLoss || data.report.amountLoss;
  } else if (data?.transactions && data?.transactions?.length > 0) {
    data.transactions.forEach((transaction: any) => {
      if (typeof transaction.amount === 'number') {
        totalLoss += transaction.amount;
      }
    });
  }
  return totalLoss;
};

export const getSessionDataPermissions = () => {
  try {
    const state = store.getState();
    const userPermissions = state.auth.sessionUser;
    if (userPermissions?.permissions) {
      return userPermissions.permissions;
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

export const checkPermissions = (allowedPermissions: string[] = []) => {
  let userPermissions = getSessionDataPermissions();
  if (userPermissions?.length === 0 || allowedPermissions?.length === 0) {
    return true;
  }
  return userPermissions.some((permission: any) => allowedPermissions.includes(permission));
};

export const cleanUpUserId = (userId: string, platform: string) => {
  if (!userId) return '';
  if (
    platform !== 'Carousell' &&
    !platform.toLowerCase().includes('other') &&
    !platform.toLowerCase().includes('google')
  ) {
    userId = userId.toLowerCase();
  }
  userId = userId.trim();
  if (userId.charAt(0) === '@') {
    userId = userId.replace('@', '');
  }
  if (userId.startsWith('t.me/')) {
    userId = userId.replace('t.me/', '');
  }
  return userId ? userId.replace(/[\t\n\r]/g, '').trim() : '';
};

export const getFullUrl = (url: any) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `http://${url}`;
  }
  return url;
};
export const getValueByPath = (obj: any, path: string) => {
  return path
    .split(/[\.\[\]]/)
    .filter(Boolean)
    .reduce((acc, part) => {
      if (acc === undefined || acc === null) {
        return undefined;
      }

      // Convert part to number if it's a valid index
      const index = Number(part);
      if (!isNaN(index)) {
        return acc[index];
      }

      return acc[part];
    }, obj);
};

export const validateIdNotNil = (value: string) => {
  console.log('value', value);
  if (
    value.trim() === '' ||
    value.toLowerCase() === 'nil' ||
    value.toLowerCase() === 'na' ||
    value.toLowerCase() === 'unknown' ||
    value.toLowerCase() === 'none' ||
    value.toLowerCase() === '-'
  ) {
    console.log('nil', value);

    return 'Please enter a valid userId';
  }
};

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
export const base64ToBlob = (base64: string, contentType: string) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
};

export const filterEmptyRows = (data: any) => {
  let tempData = cloneDeep(data);
  if (tempData && tempData?.length > 0) {
    tempData.forEach((group: any) => {
      if (!group.rows || !Array.isArray(group.rows)) return [];

      group.rows = group.rows.filter((row: any) => {
        // Skip __typename from GraphQL
        const keys = Object.keys(row).filter((key) => key !== '__typename');

        // Check if ANY value is not empty (keep row if true)
        return !keys.every((key) => {
          const value = row[key];
          return (
            value === '' ||
            value === null ||
            value === undefined ||
            (Array.isArray(value) && value.length === 0) ||
            (typeof value === 'object' && value !== null && Object.keys(value).length === 0) ||
            key === 'explanation'
          );
        });
      });
    });

    return tempData.filter((group: any) => {
      if (!group.rows || !Array.isArray(group.rows)) return false;

      // Filter out empty rows
      group.rows = group.rows.filter((row: any) => {
        if (!row || Object.keys(row).length === 0) return false;
        return Object.values(row).some((val) => val !== '' && val !== null && val !== undefined);
      });

      // Keep group only if it has non-empty rows
      return group.rows.length > 0;
    });
  } else {
    return [];
  }
};

export const countNonEmptyRows = (data: any) => {
  // console.log('data', data);
  let filteredData = filterEmptyRows(cloneDeep(data));
  let count = 0;
  // console.log('filteredData', filteredData);
  filteredData.forEach((group: any) => {
    // console.log('group', group);
    count += group.rows.length;
  });
  // console.log('count', count);
  return count;
};

export const validateFormValues = (values: any, config: Array<FormItemInterface>) => {
  const errorMessages: string[] = [];

  const validateField = (value: any, rules: any, fieldId: string) => {
    if (rules?.required && (!value || value === '')) {
      errorMessages.push(
        typeof rules.required === 'string' ? rules.required : `${fieldId} is required`,
      );
      return [];
    }

    // if (rules?.pattern && value) {
    //   const pattern = rules.pattern.value || rules.pattern;
    //   console.log('asda', { pattern, value });
    //   if (!pattern.test(value)) {
    //     errorMessages.push(rules.pattern.message || `Invalid format for ${fieldId}`);
    //     return;
    //   }
    // }

    if (rules?.validate && value) {
      Object.entries(rules.validate).forEach(([validatorKey, validatorFn]: [string, any]) => {
        const result = validatorFn(value);
        if (result !== true && result !== undefined) {
          errorMessages.push(result);
        }
      });
    }
  };

  // Validate each field
  config.forEach((field: FormItemInterface) => {
    const value = values[field.id];

    if (field.optional && (!value || value === '')) {
      return [];
    }

    if (Array.isArray(value)) {
      value.forEach((item: any, index: number) => {
        if (field.extras?.tableFields) {
          field.extras.tableFields.forEach((tableField: FormItemInterface) => {
            if (item[tableField.id]) {
              validateField(
                item[tableField.id],
                tableField.rules,
                `${field.label || field.id} row ${index + 1} ${tableField.label || tableField.id}`,
              );
            }
          });
        }
      });
      return;
    }

    validateField(value, field.rules, field.label || field.id);
  });
  console.log('errorMessages', errorMessages);
  return errorMessages;
};
