import { getFormItems } from './AdminCreateReportFormConfig';

import {
  platform,
  bankPlatforms,
  cryptoPlatforms,
  fintechPlatforms,
  payNowPlatforms,
} from '@interface/constant';
import Dropdown from '@components/common/dropdown/Dropdown';
import { CommonButton, IconButton } from '@components/common/CommonButton';

import DynamicTable from '@components/common/DynamicTable';
import { useFieldArray } from 'react-hook-form';
import { useRef } from 'react';

const AdminCreateReportMonikers = ({
  formMethods,
  selectedCategory,
  sectionRefs,
}: {
  formMethods: any;
  selectedCategory: any;
  sectionRefs: any;
}) => {
  const {
    fields: platformFields,
    append: addPlatform,
    remove: removePlatform,
  } = useFieldArray({
    control: formMethods.control,
    name: `scamEnablers[${selectedCategory}]`,
  });
  const containerRef = useRef<HTMLDivElement>(null);

  const getPlatformPath = (index: number) => {
    return `scamEnablers[${selectedCategory}][${index}].platform`;
  };

  const getPlatformForIndex = (index: number) => {
    return formMethods.getValues(getPlatformPath(index));
  };

  const getType = () => {
    let type: any = [];
    if (selectedCategory === 'Banks') {
      type = bankPlatforms;
    } else if (selectedCategory === 'Paynow') {
      type = payNowPlatforms;
    } else if (selectedCategory === 'Fintech') {
      type = [...fintechPlatforms, ...cryptoPlatforms];
    } else if (selectedCategory === 'Monikers') {
      type = platform.filter((platform) => {
        return platform != 'Website';
      });
    }
    return type;
  };

  const resetValues = (index: number) => {
    let resetPath = `scamEnablers[${selectedCategory}][${index}].rows`;
    formMethods.resetField(resetPath);
  };

  return (
    <div className="flex flex-col gap-2 w-full max-w-full">
      <div className="flex flex-row">
        <h3>{selectedCategory === 'Fintech' ? 'Fintech/Crypto' : selectedCategory}</h3>
        {selectedCategory === 'Monikers' && (
          <div className="w-[70vw]">
            <div className="flex justify-end">
              <CommonButton
                title="Add Another Platform"
                variant={'text-blue'}
                onClick={async () => {
                  await addPlatform([{}]);
                  setTimeout(() => {
                    if (containerRef.current) {
                      containerRef.current.scrollTop = containerRef.current.scrollHeight;
                    }
                    const lastFormSection = sectionRefs.current[platformFields.length];
                    if (lastFormSection) {
                      lastFormSection.scrollIntoView({ behavior: 'smooth' });
                    }
                  }, 100);
                }}
                type="button"
              />
            </div>
          </div>
        )}
      </div>
      <div className="pb-8 h-[45vh] 2xl:h-[52vh] overflow-y-auto " ref={containerRef}>
        {platformFields.map((field: any, index: number) => (
          <div className="mb-4" key={index}>
            {selectedCategory === 'Monikers' && (
              <div className="flex flex-row gap-4">
                <p className="font-bold my-4">Platform</p>
                {index > 0 && (
                  <IconButton
                    icon="delete"
                    isDisabled={false}
                    onClick={() => {
                      removePlatform(index);
                    }}
                  />
                )}
              </div>
            )}
            {selectedCategory === 'Monikers' && (
              <div className="w-48 pb-4">
                <Dropdown
                  control={formMethods.control}
                  id={getPlatformPath(index)}
                  dropdownData={getType()}
                  rules={{ required: 'Please select an option' }}
                  formState={formMethods.formState}
                  setValue={formMethods.setValue}
                  watch={formMethods.watch}
                  onChange={(selected: string) => resetValues(index)}
                ></Dropdown>
              </div>
            )}

            {(getPlatformForIndex(index) || selectedCategory !== 'Monikers') && (
              <DynamicTable
                tableId={`scamEnablers[${selectedCategory}][${index}].rows`}
                formFields={getFormItems(getPlatformForIndex(index), selectedCategory)}
                isEditable={true}
                formMethods={formMethods}
                defaultRow={{}}
                showCounter={false}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminCreateReportMonikers;
