import ModalLayout from '@components/layouts/ModalLayout';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import { Modal } from '@mui/material';
import { useState } from 'react';
import DynamicTable from './DynamicTable';
import { CommonButton, IconButton } from './CommonButton';
import { useForm, FormProvider } from 'react-hook-form';
import { getValueByPath } from '@utils/utils';

const DynamicTableModal = ({
  field,
  formMethods,
  isEditable,
  data,
}: {
  field: FormItemInterface;
  formMethods: any;
  isEditable: boolean;
  data: any;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const tempFormMethods = useForm({ defaultValues: formMethods.getValues() });

  const handleSave = () => {
    const values = tempFormMethods.getValues();
    let transactionData = getValueByPath(values, field.id);

    formMethods.setValue(field.id, transactionData);
    setIsOpen(false);
  };

  const getCount = () => {
    let rows = formMethods.getValues(field.id);
    if (rows) {
      let defaultRow = field?.extras?.defaultRow;
      let validRows = rows.filter((row: any) => {
        return !Object.keys(row).every((key) => row[key] === defaultRow[key]);
      });

      return validRows.length;
    } else {
      return '';
    }
  };
  return (
    <>
      <div className="flex flex-row gap-2 items-center">
        {!isEditable && (
          <>
            {getCount() > 0 && (
              <CommonButton
                isDisabled={false}
                variant="underline-blue-text"
                title={getCount()}
                onClick={() => setIsOpen(true)}
              />
            )}
          </>
        )}
        {isEditable && (
          <>
            {getCount() > 0 && <p>{getCount()}</p>}
            <IconButton
              isDisabled={false}
              icon="add"
              onClick={() => setIsOpen(true)}
              isLoading={false}
              type="button"
            />
          </>
        )}
      </div>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <ModalLayout
          title={field.label}
          handleCloseModal={() => {
            setIsOpen(false);
          }}
          alignment="center"
          size="large"
        >
          <>
            <DynamicTable
              tableId={field.id}
              formFields={field.extras?.tableFields}
              isEditable={isEditable}
              formMethods={tempFormMethods}
              data={data}
              defaultRow={field.extras?.defaultRow}
              showCounter={field.extras?.showCounter}
            />
            <div className="flex justify-end gap-4 pt-4">
              {isEditable && (
                <>
                  <CommonButton
                    isDisabled={false}
                    variant="text"
                    title={`Cancel`}
                    onClick={() => setIsOpen(false)}
                  />

                  <CommonButton
                    isLoading={false}
                    variant="primary"
                    title="Save"
                    onClick={handleSave}
                  />
                </>
              )}
            </div>
          </>
        </ModalLayout>
      </Modal>
    </>
  );
};

export default DynamicTableModal;
