import { useEffect, useState } from 'react';
import { ReportInterface } from '@interface/ScamEnablerInterface';
import { CommonButton, CommonButtonGroup, IconButton } from '@components/common/CommonButton';
import AssignModal from './monikers/modals/AssignModal';
import { monikerOutcomeStatusMapping } from '@interface/constant';
import { useForm } from 'react-hook-form';
import FilterModal from '@components/common/FilterModal';
import { monikerfilterGeneralItems } from './monikers/MonikerFormConfig';
import FilterAssets from '@components/common/FilterAssets';
import {
  useCreateProductionOrderV2Mutation,
  useDownloadProductionOrder,
  useDownloadProductionOrders,
  useSendProductionOrderQueue,
} from '@api/productionOrderSlice';
import ProductionOrderCgcGenerateModal from './monikers/modals/ProductionOrderCgcGenerateModal';
import Promise from 'bluebird';
import UploadCreateExcelModal from './monikers/modals/UploadCreateExcelModal';
import { Modal } from '@mui/material';
import ModalLayout from '@components/layouts/ModalLayout';
import CreateReportComponent from '@pages/reports/CreateReportPage/CreateReportComponent';
import UpdateModal from './monikers/modals/UpdateOutcomeModal';
import DownloadDateRangeModal from './monikers/modals/DownloadDateRangeModal';
import DownloadBulkProductionOrderModal from './monikers/modals/DownloadBulkProductionOrderModal';
import { showAlert } from '@store/alertSlice';
import { useDispatch } from 'react-redux';
import UpdateAssessmentFindingsMonikerPresubmitChecklist1Modal from './monikers/modals/UpdateAssessmentFindingsMonikerPresubmitChecklist1Modal';
import AssignMeConfirmationModal from './banks/modals/AssignMeConfirmationModal';
import BulkUpdateModal from './banks/modals/BulkUpdateModal';
import { getSessionUserFromAccessToken } from '@utils/utils';
import { useAssignUserToFinancialInstituteMutation } from '@api/financialInstituteSlice';
import BankProductionOrderModal from './banks/modals/productionOrderModal';
import { useNavigate } from 'react-router-dom';

export function MonikerTableGroupButtonSecondary({
  selectedScamEnablers,
  showSelectedScamEnablers,
  callback,
}: {
  selectedScamEnablers: any[];
  showSelectedScamEnablers: boolean;
  callback: () => void;
}) {
  const [assignOpen, setAssignOpen] = useState(false);
  const [updateOutcomeOpen, setUpdateOutcomeOpen] = useState(false);
  const [updateAssessmentFindingsOpen, setUpdateAssessmentFindingsOpen] = useState(false);

  return (
    <>
      <CommonButtonGroup
        dropdownOptions={[
          {
            key: 'assign',
            title: 'Assign',
            disabled: selectedScamEnablers.length <= 0,
            onClick: () => {
              setAssignOpen(true);
            },
            allowedPermissions: ['portfolios.scamenablers.assign'],
          },
          {
            key: 'updateOutcome',
            title: 'Update Outcome',
            disabled: false,
            onClick: () => {
              setUpdateOutcomeOpen(true);
            },
            allowedPermissions: ['portfolios.scamenablers.update'],
          },
          {
            key: 'updateFindings',
            title: 'Update Assessment Findings',
            disabled:
              selectedScamEnablers.length <= 0 ||
              selectedScamEnablers.filter((scamEnabler: any) => {
                return (
                  scamEnabler.outcome === 'Pending Review' ||
                  scamEnabler.outcome === 'Not Terminated' ||
                  scamEnabler.outcome === 'No Action Required'
                );
              }).length === 0,
            onClick: () => {
              setUpdateAssessmentFindingsOpen(true);
            },
            // disabledMessage: `Please select monikers from the table with the same platform`,
          },
        ]}
        isDisabled={false}
        variant="secondary"
        title={`More Actions`}
        allowedPermissions={['portfolios.scamenablers.update', 'portfolios.scamenablers.assign']}
      />
      {assignOpen && (
        <AssignModal
          selectedScamEnablers={selectedScamEnablers}
          showSelectedScamEnablers={showSelectedScamEnablers}
          assignOpen={assignOpen}
          onAssignClose={() => setAssignOpen(false)}
          callback={callback}
        />
      )}
      {updateOutcomeOpen && (
        <UpdateModal
          selectedScamEnablers={selectedScamEnablers}
          showSelectedScamEnablers={showSelectedScamEnablers}
          isOpen={updateOutcomeOpen}
          handleClose={() => setUpdateOutcomeOpen(false)}
          callback={callback}
        />
      )}
      {updateAssessmentFindingsOpen && (
        <UpdateAssessmentFindingsMonikerPresubmitChecklist1Modal
          selectedScamEnablers={selectedScamEnablers}
          isOpen={updateAssessmentFindingsOpen}
          handleClose={() => setUpdateAssessmentFindingsOpen(false)}
          callback={callback}
        />
      )}
    </>
  );
}

export function AssignBankButton({
  selectedBanks,
  clearSelectedRows,
}: {
  selectedBanks: any;
  clearSelectedRows: any;
}) {
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  return (
    <>
      <CommonButton
        variant={'secondary'}
        title={'Assign to Me'}
        onClick={() => {
          setAssignModalOpen(true);
        }}
        isDisabled={selectedBanks.length == 0}
      />

      {assignModalOpen && (
        <AssignMeConfirmationModal
          selectedBankAccounts={selectedBanks}
          assignOpen={assignModalOpen}
          onAssignClose={() => {
            setAssignModalOpen(false);
          }}
          callback={() => {
            clearSelectedRows();
          }}
        />
      )}
    </>
  );
}

export function UpdateOutcomeButton({
  selectedBanks,
  type = 'text',
  callback,
}: {
  selectedBanks: any;
  type: 'icon' | 'text';
  callback?: any;
}) {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      {type === 'text' && (
        <CommonButton
          variant={'primary'}
          title={'Update Outcome'}
          isDisabled={selectedBanks.length == 0}
          onClick={() => setModalOpen(true)}
        />
      )}
      {type === 'icon' && (
        <IconButton
          icon="edit"
          isDisabled={selectedBanks.length == 0}
          onClick={() => setModalOpen(true)}
        />
      )}
      <BulkUpdateModal
        selectedBanks={selectedBanks}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        callback={callback}
      />
    </>
  );
}

export function AssignSingleButton({
  selected,
  type,
  callback,
}: {
  selected: string;
  type?: string;
  callback: any;
}) {
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [myUser, setMyUser] = useState({
    userId: '',
    firstName: '',
    lastName: '',
  });

  useEffect(() => {
    getSessionUserFromAccessToken().then((userSession: any) => {
      setMyUser(userSession);
    });
  }, []);

  const [assignUser] = useAssignUserToFinancialInstituteMutation();
  const dispatch = useDispatch();

  const assignToMe = async () => {
    setIsSubmitting(true);

    let r: any = await Promise.all(
      [selected].map((bankAccount: any) => {
        let params = {
          financialInstituteId: bankAccount._id,
          dutyOfficer: myUser.userId,
        };

        return assignUser(params);
      }),
    );

    if (
      r.filter((resp: any) => {
        return !resp.error;
      }).length == r.length
    ) {
      dispatch(
        showAlert({
          type: 'success',
          message: `Selected bank account(s) assigned to ${myUser.firstName} ${myUser.lastName} successfully.`,
        }),
        callback(),
      );
    } else {
      dispatch(
        showAlert({
          type: 'error',
          message: `An unexpected error occurred while assigning bank account(s). Please try again later. `,
        }),
      );
    }

    setIsSubmitting(false);
  };

  return (
    <>
      {type == 'text-button' ? (
        <CommonButton
          variant={'primary'}
          title={'Assign to me'}
          isLoading={isSubmitting}
          onClick={() => {
            assignToMe();
          }}
        />
      ) : (
        <IconButton
          onClick={() => {
            setAssignModalOpen(true);
          }}
          icon="assign"
          isDisabled={false}
        />
      )}

      {assignModalOpen && (
        <AssignMeConfirmationModal
          selectedBankAccounts={[selected]}
          assignOpen={assignModalOpen}
          onAssignClose={() => {
            setAssignModalOpen(false);
          }}
          callback={callback}
        />
      )}
    </>
  );
}

export function DownloadButton({
  selectedScamEnablers,
}: {
  selectedScamEnablers: Array<ReportInterface>;
}) {
  const dispatch = useDispatch();
  const [dateRangeModalOpen, setDateRangeModalOpen] = useState(false);
  const [productionOrderModalOpen, setProductionOrderModalOpen] = useState(false);

  const [downloadType, setDownloadType] = useState('');
  const [isDownloadingProductionOrders, setIsDownloadingProductionOrders] = useState(false);

  const [invalidScamEnablers, setInvalidScamEnablers] = useState<any[]>([]);
  const [productionOrderIds, setProductionOrderIds] = useState<any[]>([]);

  const downloadProductionOrders = useDownloadProductionOrders();

  const handleDownloadProductionOrders = async () => {
    let productionOrderIds: any[] = [];
    let scamEnablersWithoutPO: any[] = [];
    selectedScamEnablers.forEach((scamEnabler: any) => {
      let hasProductionOrder = false;
      if (scamEnabler.productionOrders) {
        scamEnabler.productionOrders.forEach((productionOrder: any) => {
          productionOrderIds.push(productionOrder.productionOrderId);
          hasProductionOrder = true;
        });
      }
      if (!hasProductionOrder) {
        scamEnablersWithoutPO.push(scamEnabler.scamEnablerUserId);
      }
    });
    if (scamEnablersWithoutPO.length > 0) {
      if (productionOrderIds.length > 0) {
        setInvalidScamEnablers(scamEnablersWithoutPO);
        setProductionOrderIds(productionOrderIds);
        setProductionOrderModalOpen(true);
      } else {
        dispatch(
          showAlert({
            type: 'error',
            message: `Selected Monikers have no Production Orders generated`,
          }),
        );
      }
    } else {
      setIsDownloadingProductionOrders(true);
      let response = await downloadProductionOrders(productionOrderIds, 'v1', true);
      setIsDownloadingProductionOrders(false);
    }
  };

  return (
    <>
      <CommonButtonGroup
        dropdownOptions={[
          {
            key: 'dailyReturns',
            title: 'OCHA Daily Returns',
            disabled: false,
            onClick: () => {
              setDownloadType('dailyReturns');
              setDateRangeModalOpen(true);
            },
            allowedPermissions: ['portfolios.dailyreturns.download'],
          },
          {
            key: 'productionOrders',
            title: 'Production Orders',
            disabled: selectedScamEnablers?.length === 0,
            isLoading: isDownloadingProductionOrders,
            onClick: () => {
              handleDownloadProductionOrders();
            },
            allowedPermissions: ['portfolios.productionorders.download'],
          },
          {
            key: 'monikers',
            title: 'Monikers',
            disabled: false,
            onClick: async () => {
              setDownloadType('monikers');
              setDateRangeModalOpen(true);
            },
            allowedPermissions: ['portfolios.scamenablers.download'],
          },
          {
            key: 'statistics',
            title: 'Statistics',
            disabled: false,
            onClick: async () => {
              setDownloadType('monikerStatistics');
              setDateRangeModalOpen(true);
            },
            allowedPermissions: ['portfolios.scamenablers.download'],
          },
        ]}
        isDisabled={false}
        variant="secondary"
        title={`Download`}
        allowedPermissions={[
          'portfolios.scamenablers.download',
          'portfolios.productionorders.download',
          'portfolios.dailyreturns.download',
        ]}
      />
      {dateRangeModalOpen && (
        <DownloadDateRangeModal
          isOpen={dateRangeModalOpen}
          downloadType={downloadType}
          onClose={() => setDateRangeModalOpen(false)}
        />
      )}
      {productionOrderModalOpen && (
        <DownloadBulkProductionOrderModal
          isOpen={productionOrderModalOpen}
          invalidScamEnablers={invalidScamEnablers}
          productionOrderIds={productionOrderIds}
          onClose={() => setProductionOrderModalOpen(false)}
        />
      )}
    </>
  );
}

export function UpdateSingleButton({
  scamEnabler,
  showSelectedScamEnablers,
  callback,
  isDisabled,
}: {
  scamEnabler: any;
  showSelectedScamEnablers: boolean;
  callback: () => void;
  isDisabled?: boolean;
}) {
  const [updateOpen, setUpdateOpen] = useState(false);

  return (
    <>
      <IconButton
        onClick={() => {
          setUpdateOpen(true);
        }}
        icon="edit"
        isLoading={false}
        isDisabled={false}
        allowedPermissions={['portfolios.scamenablers.update']}
      />
      {updateOpen && (
        <UpdateModal
          selectedScamEnablers={[scamEnabler]}
          showSelectedScamEnablers={showSelectedScamEnablers}
          isOpen={updateOpen}
          handleClose={() => setUpdateOpen(false)}
          callback={callback}
        />
      )}
    </>
  );
}

export function MonikerTableGroupButtonPrimary({ callback }: { callback: () => void }) {
  let isOldView = true;
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const navigate = useNavigate();

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  };
  const handleSubmitted = (submissionId: string) => {
    setShowCreateModal(false);
    if (callback) callback();
  };

  return (
    <>
      <CommonButtonGroup
        dropdownOptions={[
          {
            key: 'manual',
            title: 'Manual Entry',
            disabled: false,
            onClick: () => {
              if (isOldView) setShowCreateModal(true);
              else navigate('/admin/reports/create?route=monikers');
            },
          },
          {
            key: 'csv',
            title: 'Upload List',
            disabled: false,
            onClick: () => {
              setDownloadOpen(true);
            },
          },
        ]}
        isDisabled={false}
        variant="primary"
        title={`Add Reports`}
        allowedPermissions={['portfolios.scamenablers.create']}
      />
      <Modal open={showCreateModal} onClose={handleCloseCreateModal}>
        <ModalLayout
          title={'Add Report - Manual Entry'}
          handleCloseModal={handleCloseCreateModal}
          alignment="center"
          size="medium-form"
          overflowClass=""
        >
          <CreateReportComponent
            showHeader={false}
            handleCloseModal={handleCloseCreateModal}
            handleSubmitted={(submissionId: string) => handleSubmitted(submissionId)}
            isFormCreateReport={false}
          />
        </ModalLayout>
      </Modal>
      {downloadOpen && (
        <UploadCreateExcelModal
          id={'fileUpload'}
          isOpen={downloadOpen}
          onClose={() => setDownloadOpen(false)}
          callback={callback}
        />
      )}
    </>
  );
}

export function ReportTransactionActionButton({
  startEdit,
  reportId,
  isAssignedToMe,
}: {
  startEdit: () => void;
  reportId: string;
  isAssignedToMe: boolean;
}) {
  return (
    <div className="absolute top-6 right-5 rounded-s hover:cursor">
      <CommonButtonGroup
        dropdownOptions={[
          {
            key: 'edit',
            title: 'Edit',
            disabled: !isAssignedToMe,
            onClick: startEdit,
          },
          {
            key: 'report',
            title: 'Go to Report',
            disabled: false,
            onClick: () => {
              window.open(`/admin/reports/${reportId}`, '_blank');
            },
          },
        ]}
        variant={'secondary'}
        title={''}
        icon="more-darkblue"
      />
    </div>
  );
}

export function AssignButton({
  selectedScamEnablers,
  showSelectedScamEnablers,
  callback,
  isDisabled,
}: {
  selectedScamEnablers: Array<ReportInterface>;
  showSelectedScamEnablers: boolean;
  callback: () => void;
  isDisabled?: boolean;
}) {
  const [assignOpen, setAssignOpen] = useState(false);
  const onAssignClose = () => {
    setAssignOpen(false);
  };

  return (
    <>
      <CommonButton
        isDisabled={isDisabled || false}
        variant="primary"
        title={`Assign`}
        onClick={() => setAssignOpen(true)}
        allowedPermissions={['portfolios.scamenablers.assign']}
      />
      {assignOpen && (
        <AssignModal
          selectedScamEnablers={selectedScamEnablers}
          showSelectedScamEnablers={showSelectedScamEnablers}
          assignOpen={assignOpen}
          onAssignClose={onAssignClose}
          callback={callback}
        />
      )}
    </>
  );
}

export function AssignMeSingleButton({
  callback,
  showSelectedScamEnablers,
  scamEnabler,
}: {
  callback: () => void;
  showSelectedScamEnablers: boolean;
  scamEnabler: ReportInterface;
}) {
  const [assignOpen, setAssignOpen] = useState(false);
  const onAssignClose = () => {
    setAssignOpen(false);
  };
  return (
    <>
      <IconButton
        onClick={() => {
          setAssignOpen(true);
        }}
        icon="assign"
        isLoading={false}
        isDisabled={false}
        allowedPermissions={['portfolios.scamenablers.assign']}
      />
      {assignOpen && (
        <AssignModal
          selectedScamEnablers={[scamEnabler]}
          showSelectedScamEnablers={showSelectedScamEnablers}
          assignOpen={assignOpen}
          onAssignClose={onAssignClose}
          callback={callback}
        />
      )}
    </>
  );
}

export function PortfoliosMonikerFilterbutton({
  setTableParams,
  tableParams,
  defaultFilterValues,
}: {
  setTableParams: any;
  tableParams: any;
  defaultFilterValues: any;
}) {
  const formMethods = useForm({
    defaultValues: defaultFilterValues,
    mode: 'onChange',
  });

  const handleFiltersChange = (filters: any) => {
    if (filters.outcome) {
      let newOutcomeStatusFilters: Array<string> = [];
      filters.outcome.forEach((outcome: string) => {
        let statusCode = Object.keys(monikerOutcomeStatusMapping).find(
          (key) => monikerOutcomeStatusMapping[key] === outcome,
        );
        if (statusCode) newOutcomeStatusFilters.push(statusCode);
      });
      filters.outcome = newOutcomeStatusFilters.join(',');
    }
    if (filters.platforms) {
      filters.platforms = filters.platforms.join(',');
    }
    if (filters.scam_type) {
      filters.scam_type = filters.scam_type.join(',');
    }
    if (filters.created_date_from) {
      filters.created_date_from = Date.parse(filters.created_date_from);
    }
    if (filters.created_date_to) {
      filters.created_date_to = Date.parse(filters.created_date_to);
    }
    if (filters.po_sent_date_from) {
      filters.po_sent_date_from = Date.parse(filters.po_sent_date_from);
    }
    if (filters.po_sent_date_to) {
      filters.po_sent_date_to = Date.parse(filters.po_sent_date_to);
    }
    if (filters.da1) {
      filters.da1 = filters.da1.id;
    }
    if (filters.da2) {
      filters.da2 = filters.da2.id;
    }
    setTableParams({
      ...tableParams,
      filters,
    });
  };

  const [filterOpen, setFilterOpen] = useState(false);

  return (
    <>
      <CommonButton
        isDisabled={false}
        variant="secondary"
        title={`Filters`}
        onClick={() => setFilterOpen(true)}
      />
      <FilterAssets
        defaultValues={defaultFilterValues}
        formMethods={formMethods}
        handleFiltersChange={handleFiltersChange}
      />
      <FilterModal
        filterOpen={filterOpen}
        formItems={monikerfilterGeneralItems}
        formMethods={formMethods}
        onClearFilters={() => formMethods.reset(defaultFilterValues)}
        onFilterClose={() => setFilterOpen(false)}
        onSubmitFilter={(filters: any) => {
          handleFiltersChange(filters);
        }}
      />
    </>
  );
}

export function GenerateProductionOrderBankButton({
  financialInstituteData,
  reportNumber,
  callback,
  handleCloseModal,
}: {
  financialInstituteData: any;
  reportNumber?: string;
  callback: any;
  handleCloseModal?: any;
}) {
  const sendProductionOrderQueue = useSendProductionOrderQueue();

  const [createProductionOrderV2] = useCreateProductionOrderV2Mutation();
  const downloadProductionOrder = useDownloadProductionOrder();
  const downloadProductionOrders = useDownloadProductionOrders();

  const [isLoading, setIsLoading] = useState(false);
  const [poModalOpen, setPoModalOpen] = useState(false);
  const [type, setType] = useState('freeze');
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = (type: string) => {
    setType(type);
    if (reportNumber) {
      handleSubmit(reportNumber, type);
    } else {
      setPoModalOpen(true);
    }
  };

  const handleSubmit = async (reportNumber: string, type: string) => {
    try {
      setIsLoading(true);
      let productionOrderRequest: Array<any> = [];
      if (!financialInstituteData.financialInstitute.poQueueId) {
        productionOrderRequest.push({
          scamEnablerId: financialInstituteData.financialInstitute._id,
          reportNumber: reportNumber,
          type: type,
        });
      } else {
        productionOrderRequest.push({
          scamEnablerId: financialInstituteData.financialInstitute.scamEnablerId,
          poQueueId: financialInstituteData.financialInstitute.poQueueId,
          reportNumber: reportNumber,
          type: type,
        });
      }

      let params = {
        productionOrderRequest: productionOrderRequest,
        channel: 'Bank',
      };
      let response = await createProductionOrderV2(params);

      if ('error' in response) {
        console.log('error', response.error);
        throw new Error(JSON.stringify(response.error));
      } else if (response.data) {
        if (response.data.productionOrder.length === 1) {
          downloadProductionOrder(response.data.productionOrder[0].productionOrderId, 'v1', false);
        } else {
          let productionOrderIds = response.data.productionOrder.map(
            (row: any) => row.productionOrderId,
          );
          await downloadProductionOrders(productionOrderIds, 'v1', false);
        }

        setIsLoading(false);
        callback();
        if (handleCloseModal) handleCloseModal();
      }
    } catch (e) {
      setIsLoading(false);
      if (handleCloseModal) handleCloseModal();
      console.log('error', e);
    }
    setPoModalOpen(false);
  };
  return (
    <>
      <CommonButtonGroup
        dropdownOptions={[
          {
            key: 'freeze',
            title: 'Production Order for Freezing',
            disabled: false,
            onClick: () => {
              handleClick('freeze');
            },
            // allowedPermissions: ['portfolios.scamenablers.assign'],
          },
          {
            key: 'earmark',
            title: 'Production Order for Earmark',
            disabled: false,
            onClick: () => {
              handleClick('earmark');
            },
            // allowedPermissions: ['portfolios.scamenablers.update'],
          },
        ]}
        isDisabled={false}
        variant="primary"
        isLoading={isLoading}
        title={`Generate Production Order`}
        // allowedPermissions={['portfolios.scamenablers.update', 'portfolios.scamenablers.assign']}
      />

      {poModalOpen && financialInstituteData && type && (
        <BankProductionOrderModal
          financialInstituteData={financialInstituteData}
          type={type}
          poOpen={poModalOpen}
          isLoading={isLoading}
          onPOClose={() => setPoModalOpen(false)}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
}

export function GenerateProductionOrderPaynowButton({
  paynowData,
  reportNumber,
  callback,
  handleCloseModal,
  isDisabled = false,
}: {
  paynowData: any;
  reportNumber: string;
  callback: any;
  handleCloseModal?: any;
  isDisabled: boolean;
}) {
  const [createProductionOrderV2] = useCreateProductionOrderV2Mutation();

  const [isLoading, setIsLoading] = useState(false);
  const [poModalOpen, setPoModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleClick = () => {
    handleSubmit();
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      let productionOrderRequest: Array<any> = [];
      if (paynowData) {
        productionOrderRequest.push({
          scamEnablerId: paynowData._id,
          reportNumber: reportNumber,
        });
      }

      let params = {
        productionOrderRequest: productionOrderRequest,
        channel: 'Paynow',
      };
      let response = await createProductionOrderV2(params);

      if ('error' in response) {
        console.log('error', response.error);
        dispatch(
          showAlert({
            type: 'error',
            message: `An unexpected error occurred while sending PayNow account for screening. Please try again later.`,
          }),
        );
        throw new Error(JSON.stringify(response.error));
      } else if (response.data) {
        dispatch(
          showAlert({
            type: 'success',
            message: `Screening sent successfully.`,
          }),
        );
        setIsLoading(false);
        callback();
        if (handleCloseModal) handleCloseModal();
      }
    } catch (e) {
      setIsLoading(false);
      if (handleCloseModal) handleCloseModal();
      console.log('error', e);
    }
    setPoModalOpen(false);
  };
  return (
    <>
      <CommonButton
        isDisabled={isDisabled}
        variant="primary"
        title={`Send for Screening`}
        onClick={handleClick}
        isLoading={isLoading}
        allowedPermissions={[
          'directionsQueue.productionorders.create',
          'portfolios.productionorderqueues.create',
        ]}
      />
    </>
  );
}

export function GenerateProductionOrderButton({
  scamEnablerData,
  handleCloseModal,
  callback,
  type,
  setErrorText,
  setPoButtonErrorMessage,
  sourcePage,
  isDisabled,
  preCallback,
}: {
  scamEnablerData: any;
  handleCloseModal?: () => void;
  callback: () => void;
  type: string;
  setErrorText?: any;
  setPoButtonErrorMessage?: any;
  sourcePage?: string;
  isDisabled?: boolean;
  preCallback?: any;
}) {
  const sendProductionOrderQueue = useSendProductionOrderQueue();

  const [createProductionOrderV2] = useCreateProductionOrderV2Mutation();
  const downloadProductionOrder = useDownloadProductionOrder();
  const downloadProductionOrders = useDownloadProductionOrders();

  const [isLoading, setIsLoading] = useState(false);
  const [poModalOpen, setPoModalOpen] = useState(false);
  const [channel, setChannel] = useState('OCHA');
  const [isDisabledButton, setIsDisabled] = useState(true);
  const [currentUser, setCurrentUser] = useState({
    userId: '',
    firstName: '',
    lastName: '',
  });

  useEffect(() => {
    const getSessionUser = async () => {
      let user = await getSessionUserFromAccessToken();
      setCurrentUser(user);
    };

    getSessionUser();
  }, []);

  useEffect(() => {
    if (scamEnablerData && scamEnablerData.length > 0) {
      const channels = scamEnablerData.map((row: any) => row.channel);
      const platforms = scamEnablerData.map((row: any) => row.platform);
      const productionOrderIds = scamEnablerData.map((row: any) => row.productionOrderId);

      // const allSamePlatform = platforms.every((platform: string) => platform === platforms[0]);
      const allSameChannel = channels.every((channel: string) => channel === channels[0]);
      const allSamePlatform = platforms.every((platform: string) => platform === platforms[0]);
      const hasProductionOrders = productionOrderIds.every(
        (productionOrderId: string) => productionOrderId !== '',
      );

      if (!allSameChannel) {
        setIsDisabled(true);
        if (setErrorText) setErrorText('Only able to mass generate for same channel.');
      } else if (channels[0] === 'OCHA') {
        let allowGenerateProductionOrder = false;
        setChannel('OCHA');
        if (setErrorText) setErrorText('');
        for (let scamEnabler of scamEnablerData) {
          let userAssigned = scamEnabler.assignment?.filter(
            (e: any) => e.userId === currentUser.userId,
          )[0];

          if (
            (userAssigned &&
              (currentUser as any)?.permissions?.includes('customs.sao') &&
              scamEnabler.findings?.monikerPreSubmitChecklist1?.checklist?.direction) ||
            (sourcePage === 'directionsQueue' &&
              userAssigned &&
              (currentUser as any)?.permissions?.includes('customs.sao'))
          ) {
            allowGenerateProductionOrder = true;
            break;
          }
        }

        setIsDisabled(!allowGenerateProductionOrder);
      } else if (channels[0] === 'Community Guideline') {
        setChannel('Community Guideline');
        if (allSamePlatform) {
          if (setErrorText) setErrorText('');
          setIsDisabled(false);
        } else {
          if (setErrorText) {
            setErrorText('Only monikers with the same brand can mass generate production order.');
            setIsDisabled(true);
          }
        }
      }
    } else {
      setIsDisabled(true);
    }
    if (isDisabled !== undefined) setIsDisabled(isDisabled);
  }, [scamEnablerData, currentUser, setErrorText]);

  const handleClick = async () => {
    let poChannel = channel;
    if (preCallback) {
      poChannel = await preCallback();
    }
    if (poChannel === 'OCHA') {
      handleSubmit('OCHA');
    } else {
      setPoModalOpen(true);
    }
  };

  const handleSubmit = async (poChannel: string) => {
    try {
      setIsLoading(true);

      let productionOrderRequest: Array<any> = [];
      await Promise.map(scamEnablerData, async (scamEnabler: any) => {
        if (!scamEnabler.poQueueId) {
          productionOrderRequest.push({
            scamEnablerId: scamEnabler._id,
          });
        } else {
          productionOrderRequest.push({
            scamEnablerId: scamEnabler.scamEnablerId,
            poQueueId: scamEnabler.poQueueId,
          });
        }
      });

      let params = {
        productionOrderRequest: productionOrderRequest,
        channel: poChannel,
        platform: poChannel !== 'OCHA' ? scamEnablerData[0].platform : undefined,
      };
      let response = await createProductionOrderV2(params);

      if ('error' in response) {
        console.log('error', response.error);
        throw new Error(JSON.stringify(response.error));
      } else if (response.data) {
        if (response.data.productionOrder.length === 1) {
          downloadProductionOrder(response.data.productionOrder[0].productionOrderId, 'v1', false);
        } else {
          let productionOrderIds = response.data.productionOrder.map(
            (row: any) => row.productionOrderId,
          );
          await downloadProductionOrders(productionOrderIds, 'v1', false);
        }

        setIsLoading(false);
        callback();
        if (handleCloseModal) handleCloseModal();
      }
    } catch (e) {
      setIsLoading(false);
      if (handleCloseModal) handleCloseModal();
      console.log('error', e);
    }
    setPoModalOpen(false);
  };
  return (
    <>
      {type === 'text' && (
        <CommonButton
          isDisabled={isDisabled}
          variant="primary"
          title={`Generate PO`}
          onClick={handleClick}
          isLoading={isLoading}
          allowedPermissions={[
            'directionsQueue.productionorders.create',
            'portfolios.productionorderqueues.create',
          ]}
          onMouseEnter={() => {
            if (setPoButtonErrorMessage) setPoButtonErrorMessage(true);
          }}
          onMouseLeave={() => {
            if (setPoButtonErrorMessage) setPoButtonErrorMessage(false);
          }}
        />
      )}
      {type === 'icon' && (
        <IconButton
          onClick={handleClick}
          icon="generate"
          isDisabled={isDisabledButton}
          isLoading={isLoading}
        />
      )}
      {scamEnablerData && scamEnablerData[0] && (
        <ProductionOrderCgcGenerateModal
          platform={scamEnablerData[0].platform}
          data={scamEnablerData}
          openModal={poModalOpen}
          handleCloseModal={() => {
            setPoModalOpen(false);
          }}
          handleClick={() => handleSubmit('Community Guideline')}
          isLoading={isLoading}
        />
      )}
    </>
  );
}

export function ExpandBankRecordButton({
  onClick,
  isExpanded,
}: {
  onClick: any;
  isExpanded: boolean;
}) {
  return (
    <>
      <IconButton
        className={isExpanded ? 'rotate-180' : 'rotate-0'}
        icon="downArrow"
        isDisabled={false}
        size="15px"
        onClick={() => {
          onClick();
          console.log(isExpanded);
        }}
      />
    </>
  );
}

export function PaynowAddToReportButton({
  onClick,
  selectedRows,
  isDisabled,
}: {
  onClick: any;
  selectedRows: any;
  isDisabled: boolean;
}) {
  return (
    <>
      <CommonButton
        isDisabled={isDisabled}
        variant="primary"
        title={`Add to Report`}
        onClick={onClick}
      />
    </>
  );
}
