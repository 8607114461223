// These are the predefined comments/outcomes for Diary Section

export const messages = {
    SCB: {
      withPaynow: 'Paynow <paynow number> is linked to <payment channel> <account number> which is not frozen yet. Freeze order was sent vide this report. Other unfrozen SCB accounts under the BAH will be frozen vide this report too, please check SOA for details.',
      withoutPaynow: '<payment channel> <account number> is not frozen yet. Freeze order was sent vide this report. Other unfrozen SCB accounts under the BAH will be frozen vide this report too, please check SOA for details.',
    },
    OCBCDBSUOB: {
      withPaynow: 'Paynow <paynow number> is linked to <payment channel> <account number> which is not frozen yet. Freeze order was sent vide this report. OCBC, DBS and UOB accounts with balance under $100 will not be frozen, bank will take internal actions (Project CENTUM).',
      withoutPaynow: '<payment channel> <account number> is not frozen yet. Freeze order was sent vide this report. OCBC, DBS and UOB accounts with balance under $100 will not be frozen, bank will take internal actions (Project CENTUM).',
    },
    otherBanks: {
      withPaynow: 'Paynow <paynow number> is linked to <payment channel> <account number> which is not frozen yet. Freeze order was sent vide this report.',
      withoutPaynow: '<payment channel> <account number> is not frozen yet. Freeze order was sent vide this report.',
    },
    invalidPaynow: 'No paynow records found for the reported transaction date.',
    paynowBCSPending: 'ASC will conduct Paynow screening for <payment channel> <paynow number> and freeze the linked bank account if not already frozen.',
    actedOnPreviously: '<payment channel> <account number> was already acted on by ASC previously.',
    actedOnPreviouslyWithPaynow: 'Paynow <paynow number> is linked to <payment channel> <account number> was already acted on by ASC previously.',
  };

  export const relevantOutcomesForUnfrozenBanks = [
    'Not Frozen',
    'PO Issued',
    'Earmarked Funds',
    'No monies to earmark',
  ];

  export const relevantOutcomesForRelatedPOs = [
    'PO Issued',
    'Frozen',
    'Account Closed',
    'Other Dept Froze',
    'CENTUM - Not Frozen',
    'Earmarked Funds',
    'No monies to earmark',
    'Invalid Account',
  ];